import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { Security, LoginCallback } from '@okta/okta-react';
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
// import oktaConfig from './OktaConfig.js';
// import { RequiredAuth } from './secureRoute.js';

import Home from './Pages/Final.js';
// import Countdown from './Pages/Countdown.js';
import Auth from './Pages/Auth.js';
import Rules from './Pages/Rules.js';
// import Draft from './Pages/Draft.js';
// import ForceOkta from './Pages/ForceOkta.js';
import ForceSignOut from './Pages/ForceSignOut.js';

import Admin from './Pages/Admin.js';

// const oktaAuth = new OktaAuth(oktaConfig.oidc);

function RouteFile() {
    // const navigate = useNavigate();
    // const restoreOriginalUri = (_oktaAuth, originalUri) => {
    //     navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    // }

    return(
        // <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Auth />} />
                <Route path='/rules' element={<Rules />} />
                {/* <Route path='/draft' element={<Draft />} /> */}
                {/* <Route path='login/callback' element={<Auth loadingElement={<Loader />} />} /> */}
                {/* <Route path='/protected' element={<RequiredAuth />}>
                    <Route path='' element={<Alert />} />
                </Route> */}
                {/* <Route path='/login/okta' element={<ForceOkta />} /> */}

                <Route path='/logout' element={<ForceSignOut />} />

                <Route path='/admin' element={<Admin />} />
                {/* <Route path='/admin/demo' element={<Home />} /> */}
            </Routes>
        // </Security>
    );
}

export default RouteFile;