import { useEffect, useState } from 'react';
import { auth, functions } from '../FirebaseConfig';
import { GoogleAuthProvider, signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import { useOktaAuth } from '@okta/okta-react';
import { httpsCallable } from 'firebase/functions';
import { AnimatePresence, motion } from 'framer-motion';

import Loader from './Loader';
import { Link } from 'react-router-dom';

const provider = new GoogleAuthProvider();
const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible'
    }, auth);
}

function Auth() {
    // Global Consts
    // const { oktaAuth, authState } = useOktaAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [currentAuthUser, setCurrentAuthUser] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(null);

    // Alert Consts
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        text: null,
        errorText: null,
        subCategory: null,
        urgency: null,
        description: null,
        ticketButtonVisible: false,
    });

    // Code Consts
    const [authWithGoogle, setAuthWithGoogle] = useState(null);
    // const [salesForceInfo, setSalesForceInfo] = useState(null);
    const countryCode = '+1';
    const [otpPhoneNumber, setOTPPhoneNumber] = useState(countryCode);
    const [OTP, setOTP] = useState('');

    // Display Consts
    const [whatIsVisible, setWhatIsVisible] = useState('auth');
    const [phoneWhatIsVisible, setPhoneWhatIsVisible] = useState('phone');
    const [stepsCompleted, setStepsCompleted] = useState(0);

    const labelClass = 'absolute text-sm text-[#606060] -translate-y-6';
    const inputClass = 'w-full p-3 font-normal text-md text-[#000000] border-b-2 placeholder:text-[#00000050] placeholder:uppercase focus:outline-none bg-[#FFFFFF]';
    const inputInitial = { x: 300, opacity: 0, borderColor: '#E6311D50' };
    const inputAnimate = { x: 0, opacity: 1, borderColor: '#E6311D50' };
    const inputExit = { x: -300, opacity: 0, borderColor: '#E6311D50' };
    const borderAnimate = { borderColor: '#E6311D50' };
    const borderFocus = { borderColor: '#E6311D' };
    const inputTransition = { type: 'tween', duration: 0.15 };

    const fillerWidth = {
        1: '98.25%',
        2: '87.5%',
        3: '76.75%',
        4: '66%',
        5: '55.25%',
        6: '44.5%',
        7: '33.75%',
        8: '23%',
        9: '12.25%',
        10: '1.5%',
    }

    // Register Consts
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [staff, setStaff] = useState(null);
    const [spouse, setSpouse] = useState(null);
    const [sfHousehold, setSFHousehold] = useState(null);
    const [shirtSize, setShirtSize] = useState(null);
    const [skillRating, setSkillRating] = useState(1);
    const [challengeCompletion, setChallengeCompletion] = useState(1);
    const [maxPoints, setMaxPoints] = useState(1);
    const [participationType, setParticipationType] = useState(null);
    const [captainPreference, setCaptainPreference] = useState(null);
    const [captainNomination, setCaptainNomination] = useState(null);

    async function checkSalesForce() {
        console.log('CheckSF');
        const checkSalesForceFunction = httpsCallable(functions, 'checkSalesForce');
        const salesForceInfo = await checkSalesForceFunction()
        .then((result) => {
            if (!result.data.match && !result.data.staff && !result.data.spouse) {
                setIsLoading(false);
                setAlertInfo({
                    text: 'No internal account found. Please try again or submit a tech ticket.',
                    errorText: `Error Message: 'Salesforce: No matching account'`,
                    subCategory: 'Account Access',
                    urgency: 'Medium',
                    description: '*** No internal account found via Salesforce --- Location: /login ***',
                    ticketButtonVisible: true,
                });
                setAlertVisible(true);
                return 'Error';
            }

            return result.data;
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Error checking interal account. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Account Access',
                urgency: 'Medium',
                description: `*** Error communicating to Salesforce via Firebase Function (checkSalesForce) --- Error Message: '${err}' --- Location: /login ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return 'Error';
        });

        return salesForceInfo;
    }

    async function checkRegistration() {
        console.log('Checking Registration');
        const checkRegistrationFunction = httpsCallable(functions, 'checkChallengeRegistration');
        const googleInfo = await checkRegistrationFunction()
        .then((result) => {
            setRegistrationStatus(result.data);

            if (`${result.data}`.startsWith('PATH:')) {
                setIsLoading(false);
                setAlertInfo({
                    text: 'You already have an account using a different sign in method. Please try again.',
                    errorText: null,
                    subCategory: null,
                    urgency: null,
                    description: null,
                    ticketButtonVisible: false,
                });
                setAlertVisible(true);
                return result.data
            }

            if (result.data === 'Registered') {
                window.location.replace('/');
                return result.data
            }

            return result.data
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Could not verify your registration status. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Account Access',
                urgency: 'Medium',
                description: `*** Error checking registration status via Firebase Function (checkChallengeRegistration) --- Error Message: '${err}' --- Location: /login ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return 'Error'
        });

        return googleInfo;
    }

    // Code
    useEffect(() => {
        const checkState = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                await checkRegistration();

                setCurrentAuthUser(currentUser);
                setIsLoading(false);
            } else {
                // THIS ACTIVATES IF USER IS NOT LOGGED IN
                setIsLoading(false);
                return
            }
        })

        return () => {
            checkState();
        }
    }, []);

    // useEffect(() => {
    //     const init = () => {
    //         // console.log(authState);
    //         if (!authState || !authState.isAuthenticated) {
    //             if (auth.currentUser) {
    //                 // PHONE AUTHENTICATED

    //                 if (auth.currentUser?.displayName) {
    //                     const fullName = auth.currentUser?.displayName;

    //                     setFirstName(fullName.substring(0, fullName.indexOf(' ')));
    //                     setLastName(fullName.substring(fullName.indexOf(' ') + 1));
    //                 }

    //                 setPhoneNumber(auth.currentUser?.phoneNumber || '');
    //                 setEmailAddress(auth.currentUser?.email || '');
    //                 setCurrentAuthUser(auth.currentUser);

    //                 setWhatIsVisible('register');
    //                 setPhoneWhatIsVisible('phone');
    //                 setOTPPhoneNumber('+1');
    //                 setOTP('');

    //                 setIsLoading(false);
    //             } else {
    //                 // --- NO AUTHENTICATION ---

    //                 setWhatIsVisible('auth');
    //                 setCurrentAuthUser(null);

    //                 setIsLoading(false);
    //             }
    //         } else {
    //             // OKTA AUTHENTICATED

    //             if (authState.idToken.claims?.name) {
    //                 const fullName = authState.idToken.claims?.name;

    //                 setFirstName(fullName.substring(0, fullName.indexOf(' ')));
    //                 setLastName(fullName.substring(fullName.indexOf(' ') + 1));
    //             }

    //             setPhoneNumber(authState.idToken.claims?.phone || '');
    //             setEmailAddress(authState.idToken.claims?.email || '');
    //             setCurrentAuthUser(authState.idToken.claims);

    //             setWhatIsVisible('register');

    //             setIsLoading(false);
    //         }
    //     }

    //     const phoneChanged = auth.onAuthStateChanged((currentAuthUser) => {
    //         init();
    //     })

    //     init();

    //     return () => {
    //         phoneChanged();
    //     }
    // }, []);

    function signInWithGoogle() {
        setIsLoading(true);

        signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;

            if (!user?.email.includes('@elevationchurch.org') && !user?.email.includes('@elevationchurch.ca') && !user?.email.includes('@stevenfurtick.com')) {
                auth.signOut();
                setIsLoading(false);
                setAlertInfo({
                    text: 'Invalid Google account. Please try again and use a valid "@elevationchurch.org" Google account.',
                    errorText: null,
                    subCategory: null,
                    urgency: null,
                    description: null,
                    ticketButtonVisible: false,
                });
                setAlertVisible(true);
                return
            } else {
                const salesForceInfo = await checkSalesForce();
                const googleInfo = await checkRegistration();
                console.log(googleInfo);

                setAuthWithGoogle(true);
                setDisplayName(user?.displayName || salesForceInfo?.display_name || '');
                setFirstName(salesForceInfo?.first_name || '');
                setLastName(salesForceInfo?.last_name || '');

                if (!salesForceInfo?.first_name && !salesForceInfo?.last_name && user?.displayName) {
                    const fullName = user?.displayName;

                    setFirstName(fullName.substring(0, fullName.indexOf(' ')));
                    setLastName(fullName.substring(fullName.indexOf(' ') + 1));
                }

                let tempPhone = `${user?.phoneNumber || salesForceInfo?.phone || ''}`.replace(/\D/g, '');
                if (tempPhone.length === 10) {
                    setPhoneNumber('+1' + tempPhone);
                } else {
                    setPhoneNumber(tempPhone);
                }
                setEmailAddress(user?.email || salesForceInfo?.email || '');
                setProfilePic(user?.photoURL || '');
                setStaff(salesForceInfo?.staff || null);
                setSpouse(salesForceInfo?.spouse || null);
                setSFHousehold(salesForceInfo?.salesforce_account || null);
                setShirtSize(salesForceInfo?.shirt_size || null);

                setWhatIsVisible('register');
                setPhoneWhatIsVisible('phone');
                setOTPPhoneNumber('+1');
                setOTP('');

                setIsLoading(false);
                return
            }
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Unable to connect to Google. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Account Access',
                urgency: 'Medium',
                description: `*** Error connecting with Google authentication --- Error Message: '${err}' --- Location: /login ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return
        });
    }
    
    function requestOTP(e) {
        const pn = `${e}`;
    
        let pn1 = pn.replace(/[^0-9]+/g, '');
        let pn2 = '+' + pn1;
        
        const phoneNumber = pn2;
            
        if (phoneNumber.length >= 12) {
            generateRecaptcha();
    
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
            })
            .catch((err) => {
                console.error(err);
            });
        }
    }
    
    async function verifyOTP(e) {
        setIsLoading(true);

        const OTP = `${e}`;
    
        if (OTP.length === 6) {
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(OTP)
            .then(async (result) => {
                const user = result.user;

                const salesForceInfo = await checkSalesForce();

                if (salesForceInfo?.staff) {
                    auth.signOut();
                    setWhatIsVisible('auth');
                    setPhoneWhatIsVisible('phone');
                    setOTPPhoneNumber('+1');
                    setOTP('');

                    setAlertInfo({
                        text: 'You already have an account using a different sign in method. Please try again.',
                        errorText: null,
                        subCategory: null,
                        urgency: null,
                        description: null,
                        ticketButtonVisible: false,
                    });
                    setIsLoading(false);
                    setAlertVisible(true);
                    return
                }

                const googleInfo = await checkRegistration();
                console.log(googleInfo);

                setAuthWithGoogle(false);
                setDisplayName(user?.displayName || salesForceInfo?.display_name || '');
                setFirstName(salesForceInfo?.first_name || '');
                setLastName(salesForceInfo?.last_name || '');

                if (!salesForceInfo?.first_name && !salesForceInfo?.last_name && user?.displayName) {
                    const fullName = user?.displayName;

                    setFirstName(fullName.substring(0, fullName.indexOf(' ')));
                    setLastName(fullName.substring(fullName.indexOf(' ') + 1));
                }

                let tempPhone = `${user?.phoneNumber || salesForceInfo?.phone || ''}`.replace(/\D/g, '');
                if (tempPhone.length === 10) {
                    setPhoneNumber('+1' + tempPhone);
                } else {
                    setPhoneNumber(tempPhone);
                }
                setEmailAddress(user?.email || salesForceInfo?.email || '');
                setProfilePic(user?.photoURL || '');
                setStaff(salesForceInfo?.staff || null);
                setSpouse(salesForceInfo?.spouse || null);
                setSFHousehold(salesForceInfo?.salesforce_account || null);
                setShirtSize(salesForceInfo?.shirt_size || null);

                if (`${googleInfo}`.startsWith('PATH:') || googleInfo === 'Registered') {
                    setWhatIsVisible('auth');
                    setPhoneWhatIsVisible('phone');
                    setOTPPhoneNumber('+1');
                    setOTP('');

                    setIsLoading(false);
                    return
                }

                setWhatIsVisible('register');
                setPhoneWhatIsVisible('phone');
                setOTPPhoneNumber('+1');
                setOTP('');

                setIsLoading(false);
                return
            })
            .catch((err) => {
                setIsLoading(false);
                setAlertInfo({
                    text: `Unable to verify phone number. Please try again or submit a tech ticket.`,
                    errorText: `Error Message: '${err}'`,
                    subCategory: 'Account Access',
                    urgency: 'Medium',
                    description: `*** Error connecting with phone authentication --- Error Message: '${err}' --- Location: /login ***`,
                    ticketButtonVisible: true,
                });
                setAlertVisible(true);
                return
            });
        };
    }

    async function register() {
        // setIsLoading(true);

        const setUserData = {
            displayName: displayName,
            firstName: firstName,
            lastName: lastName,
            phone: phoneNumber,
            email: emailAddress,
            profilePic: profilePic,
            authWithGoogle: authWithGoogle,
            staff: staff,
            spouse: spouse,
            sfHousehold: sfHousehold,
            shirtSize: shirtSize,
            skillRating: skillRating,
            challengeCompletion: challengeCompletion,
            maxPoints: maxPoints,
            participationType: participationType,
            captainPreference: captainPreference,
            captainNomination: captainNomination,
            registrationStatus: registrationStatus,
        };

        const registerUser = httpsCallable(functions, 'registerUser');
        const returnedInfo = await registerUser({ data: setUserData })
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Error registering account. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Account Access',
                urgency: 'Medium',
                description: `*** Error registering user's account via Firebase Function (registerUser) --- Error Message: '${err}' --- Location: /login ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return 'Error';
        });

        if (returnedInfo === 'Success') {
            window.location.replace('/');
        }
    }

    // isLoading
    const LoadingOverlay = ({ isLoading }) => (
        <motion.div initial={ false } animate={{ opacity: isLoading ? 1 : 0 }} transition={{ duration: 0.2 }} style={{ pointerEvents: isLoading ? 'auto' : 'none' }} className='fixed top-0 left-0 w-screen h-screen m-0 flex justify-center items-center bg-[#00000080]'>
            <Loader />
            <Link to='/' />
        </motion.div>
    )

    return(
        <div className='w-screen h-screen'>
            <LoadingOverlay isLoading={isLoading} />
            <div className="Auth w-screen h-screen flex justify-center items-center bg-[url('./Assets/bg.jpg')] bg-cover">
                {alertVisible ?
                    <motion.div className='fixed top-0 left-0 w-screen h-screen bg-[#00000050] flex flex-col justify-center items-center' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <motion.div className='w-full max-w-4xl rounded-3xl bg-[#FFFFFF] flex flex-col justify-center items-center md:m-5 p-5 gap-10' initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0, opacity: 0 }}>
                            <h1 className='text-2xl md:text-3xl font-bold uppercase text-center'>Uh Oh! We encountered an error!</h1>
                            <h2 className='w-full text-base md:text-lg font-normal'>{alertInfo.text}</h2>
                            {alertInfo.errorText ?
                                <h2 className='w-full text-base md:text-lg font-normal'>{alertInfo.errorText}</h2>
                            : null }
                            {/* BUTTONS START */}
                            <motion.div className='w-full h-full grid grid-cols-1 md:grid-cols-2 gap-5 place-content-between'>
                                <motion.button
                                    className='w-full md:w-64 max-w-full h-14 md:h-16 justify-self-start border-[#E6311D] rounded-2xl uppercase text-[#E6311D] focus:outline-none'
                                    animate={{ borderWidth: 2, fontSize: '19px', fontWeight: 400 }}
                                    whileHover={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    whileFocus={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    onClick={() => {
                                        setAlertVisible(false);
                                    }}
                                >Close</motion.button>
                                {alertInfo.ticketButtonVisible ?
                                    <motion.button
                                        className='w-full md:w-64 max-w-full h-14 md:h-16 justify-self-end uppercase text-white bg-clip-padding rounded-2xl focus:outline-none'
                                        animate={{ borderWidth: 0, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '19px', fontWeight: 400 }}
                                        whileHover={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        whileFocus={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        onClick={() => {
                                            window.open(`https://staff.elevationchurch.org/support/tickets/new?category=Software%20/%20Development&sub_category=${encodeURI(`${alertInfo.subCategory}`)}&urgency=${encodeURI(`${alertInfo.urgency}`)}&subject=eFit%20%2D%20Staff%20Fitness%20Challenge&description=${encodeURI(`${alertInfo.description}`)}`, '_blank');
                                        }}
                                    >Submit Tech Ticket</motion.button>
                                : null }
                            </motion.div>
                            {/* BUTTONS END */}
                        </motion.div>
                    </motion.div>
                : null }
                <div id='recaptcha-container' />
                <motion.button
                    className='fixed bottom-5 right-5 w-14 aspect-square bg-[#FFFFFF] border-[#606060] text-[#606060] rounded-full'
                    animate={{ borderWidth: 2, fontSize: '23px', fontWeight: 400 }}
                    whileHover={{ borderWidth: 4, fontSize: '24px', fontWeight: 600 }}
                    whileFocus={{ borderWidth: 4, fontSize: '24px', fontWeight: 600 }}
                    onClick={() => {
                        window.open(`https://staff.elevationchurch.org/support/tickets/new?category=Software%20/%20Development&sub_category=Account%20Access&urgency=Low&subject=eFit%20%2D%20Staff%20Fitness%20Challenge&description=${encodeURI('*** Error Message: Help initiated from the login page --- Location: /login ***')}`, '_blank');
                    }}
                >?</motion.button>
                {/* CONTAINER START */}
                <motion.div layout className='w-[95%] md:w-full max-w-4xl rounded-3xl bg-[#FFFFFF] flex flex-col justify-center items-center'>
                    {/* AUTH START */}
                    {whatIsVisible === 'auth' ?
                        <motion.div layout className='w-full flex flex-col justify-center items-center m-5 p-5 gap-10 overflow-hidden' initial={{ scaleY: 0, opacity: 0 }} animate={{ scaleY: 1, opacity: 1 }} exit={{ scaleY: 0, opacity: 0 }}>
                            {/* BUTTONS START */}
                            <motion.div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 place-content-between'>
                                <motion.button
                                    className='w-full max-w-full h-14 md:h-16 uppercase text-[#FFFFFF] bg-clip-padding rounded-2xl focus:outline-none'
                                    animate={{ borderWidth: 0, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '19px', fontWeight: 400 }}
                                    whileHover={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                    whileFocus={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                    onClick={async () => {
                                        signInWithGoogle();
                                        // oktaAuth.signInWithRedirect();
                                        setIsLoading(true);
                                    }}
                                >Sign In As Staff</motion.button>
                                <motion.button
                                    className='w-full max-w-full h-14 md:h-16 border-[#E6311D] rounded-2xl uppercase text-[#E6311D] focus:outline-none'
                                    animate={{ borderWidth: 2, fontSize: '19px', fontWeight: 400 }}
                                    whileHover={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    whileFocus={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    onClick={() => setWhatIsVisible('phone')}
                                >Sign In As Staff Spouse</motion.button>
                            </motion.div>
                            {/* BUTTONS END */}
                        </motion.div>
                    : null }
                    {/* AUTH END */}
                    {/* PHONE START */}
                    {whatIsVisible === 'phone' ?
                        <motion.div layout className='w-full flex flex-col justify-center items-center m-5 p-10 gap-10 overflow-hidden' initial={{ scaleY: 0, opacity: 0 }} animate={{ scaleY: 1, opacity: 1 }} exit={{ scaleY: 0, opacity: 0 }}>
                            <div className='w-full flex flex-row justify-center items-center'>
                                {phoneWhatIsVisible === 'phone' ?
                                    <motion.div className='w-full' autoComplete='off'>
                                        <motion.input className={inputClass} initial={inputInitial} animate={inputAnimate} exit={inputExit} whileFocus={borderFocus} transition={inputTransition} key='otpPhoneNumber' type='tel' autoComplete='off' required placeholder='Phone Number' value={otpPhoneNumber} onChange={(e) => setOTPPhoneNumber(e.target.value)}></motion.input>
                                    </motion.div>
                                :
                                    <motion.div className='w-full' autoComplete='off'>
                                        <motion.input className={inputClass} initial={inputInitial} animate={inputAnimate} exit={inputExit} whileFocus={borderFocus} transition={inputTransition} key='otp' required placeholder='OTP Code' value={OTP} onChange={(e) => setOTP(e.target.value)}></motion.input>
                                    </motion.div>
                                }
                            </div>
                            {/* BUTTONS START */}
                            <motion.div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 place-content-between'>
                                <motion.button
                                    className='w-full md:w-56 max-w-full h-14 md:h-16 justify-self-start border-[#E6311D] rounded-2xl uppercase text-[#E6311D] focus:outline-none'
                                    animate={{ borderWidth: 2, fontSize: '19px', fontWeight: 400 }}
                                    whileHover={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    whileFocus={{ borderWidth: 4, fontSize: '20px', fontWeight: 600 }}
                                    onClick={() => {
                                        setWhatIsVisible('auth');
                                        setPhoneWhatIsVisible('phone');
                                        setOTPPhoneNumber('+1');
                                        setOTP('');
                                    }}
                                >Back</motion.button>
                                {phoneWhatIsVisible === 'phone' ?
                                    <motion.button
                                        className='w-full md:w-56 max-w-full h-14 md:h-16 justify-self-end uppercase text-[#FFFFFF] bg-clip-padding rounded-2xl focus:outline-none'
                                        animate={{ borderWidth: 0, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '19px', fontWeight: 400 }}
                                        whileHover={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        whileFocus={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        onClick={() => {
                                            requestOTP(otpPhoneNumber);
                                            setPhoneWhatIsVisible('code');
                                        }}
                                    >Get Code</motion.button>
                                :
                                    <motion.button
                                        className='w-full md:w-56 max-w-full h-14 md:h-16 justify-self-end uppercase text-[#FFFFFF] bg-clip-padding rounded-2xl focus:outline-none'
                                        animate={{ borderWidth: 0, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '19px', fontWeight: 400 }}
                                        whileHover={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        whileFocus={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '20px', fontWeight: 600 }}
                                        onClick={() => {
                                            verifyOTP(OTP);
                                            setIsLoading(true);
                                        }}
                                    >Verify Code</motion.button>
                                }
                            </motion.div>
                            {/* BUTTONS END */}
                        </motion.div>
                    : null }
                    {/* PHONE END */}
                    {/* REGISTER START */}
                    {whatIsVisible === 'register' ?
                        <motion.div layout className='w-full flex flex-col justify-center items-center m-5 p-10 gap-10 overflow-hidden' initial={{ scaleY: 0, opacity: 0 }} animate={{ scaleY: 1, opacity: 1 }} exit={{ scaleY: 0, opacity: 0 }}>
                            {/* STEP ICONS START */}
                            <div className='w-full flex flex-row justify-evenly items-center'>
                                <motion.div className='w-12 md:w-16 aspect-square rounded-full flex justify-center items-center bg-clip-padding' animate={ stepsCompleted < 1 ? { borderWidth: 4, borderColor: '#E6311D' } : { borderWidth: 8, borderColor: '#E6311D80', backgroundColor: '#E6311D' }}>
                                    <AnimatePresence>
                                        {stepsCompleted < 1 ?
                                            <h1 className='text-2xl md:text-3xl font-bold text-[#E6311D]'>1</h1>
                                        :
                                            <svg fill='none' viewBox='-3 -3 30 30' stroke='#FFFFFF' strokeWidth={2}>
                                                <motion.path initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} strokeLinecap='round' strokeLinejoin='round' d='M5 13l4 4L19 7' />
                                            </svg>
                                        }
                                    </AnimatePresence>
                                </motion.div>
                                <motion.div className='w-12 md:w-16 aspect-square rounded-full flex justify-center items-center bg-clip-padding' animate={ stepsCompleted < 2 ? { borderWidth: 4, borderColor: '#E6311D' } : { borderWidth: 8, borderColor: '#E6311D80', backgroundColor: '#E6311D' }}>
                                    <AnimatePresence>
                                        {stepsCompleted < 2 ?
                                            <h1 className='text-2xl md:text-3xl font-bold text-[#E6311D]'>2</h1>
                                        :
                                            <svg fill='none' viewBox='-3 -3 30 30' stroke='#FFFFFF' strokeWidth={2}>
                                                <motion.path initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} strokeLinecap='round' strokeLinejoin='round' d='M5 13l4 4L19 7' />
                                            </svg>
                                        }
                                    </AnimatePresence>
                                </motion.div>
                                <motion.div className='w-12 md:w-16 aspect-square rounded-full flex justify-center items-center bg-clip-padding' animate={ stepsCompleted < 3 ? { borderWidth: 4, borderColor: '#E6311D' } : { borderWidth: 8, borderColor: '#E6311D80', backgroundColor: '#E6311D' }}>
                                    <AnimatePresence>
                                        {stepsCompleted < 3 ?
                                            <h1 className='text-2xl md:text-3xl font-bold text-[#E6311D]'>3</h1>
                                        :
                                            <svg fill='none' viewBox='-3 -3 30 30' stroke='#FFFFFF' strokeWidth={2}>
                                                <motion.path initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} strokeLinecap='round' strokeLinejoin='round' d='M5 13l4 4L19 7' />
                                            </svg>
                                        }
                                    </AnimatePresence>
                                </motion.div>
                            </div>
                            {/* STEP ICONS END */}
                            {/* CONTENT START */}
                            <motion.div className='w-full'>
                                <motion.div className={stepsCompleted === 2 ? 'w-full grid grid-cols-1 gap-5' : 'w-full grid grid-cols-1 md:grid-cols-2 gap-5'}>
                                    {/* STEP 1 START */}
                                    {stepsCompleted === 0 ?
                                        <>
                                            <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className={labelClass}>First Name</label>
                                                <motion.input className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='firstName' type='text' required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </motion.div>
                                            <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className={labelClass}>Last Name</label>
                                                <motion.input className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='lastName' type='text' required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </motion.div>
                                            <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className={labelClass}>Phone Number</label>
                                                <motion.input className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='phoneNumber' type='tel'value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                            </motion.div>
                                            <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className={labelClass}>Email Address</label>
                                                <motion.input className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='emailAddress' type='email' autoComplete='email' value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                            </motion.div>
                                        </>
                                    : null }
                                    {/* STEP 1 END */}
                                    {/* STEP 2 START */}
                                    {stepsCompleted === 1 ?
                                        <>
                                            <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className={labelClass}>Participation Type</label>
                                                <motion.select className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='participationType' required type='text' value={participationType} onChange={(e) => setParticipationType(e.target.value)}>
                                                    <motion.option value={null}>Select An Option</motion.option>
                                                    {/* <motion.option value='team'>Team Competition (Shirt Included)</motion.option> */}
                                                    <motion.option value='individual'>Individual Competition</motion.option>
                                                </motion.select>
                                            </motion.div>
                                            <h1 className='w-full text-xs md:text-sm text-black text-center opacity-50 md:col-span-2'>**The Team Draft has been completed and is locked. You may only register for the Individual Competition.**</h1>
                                            {participationType === 'team' ?
                                                <>
                                                    <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                        <label className={labelClass}>Team Shirt Size</label>
                                                        <motion.select className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='shirtSize' type='text' value={shirtSize} onChange={(e) => setShirtSize(e.target.value)}>
                                                            <motion.option value={null}>Select An Option</motion.option>
                                                            <motion.option value='XS'>XS</motion.option>
                                                            <motion.option value='Small'>S</motion.option>
                                                            <motion.option value='Medium'>M</motion.option>
                                                            <motion.option value='Large'>L</motion.option>
                                                            <motion.option value='XL'>XL</motion.option>
                                                            <motion.option value='XXL'>XXL</motion.option>
                                                            <motion.option value='XXXL'>XXXL</motion.option>
                                                            <motion.option value='XXXXL'>XXXXL</motion.option>
                                                        </motion.select>
                                                    </motion.div>
                                                    <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                        <label className={labelClass}>Interested In Being A Captain? (Optional)</label>
                                                        <motion.select className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='captainPreference' type='text' value={captainPreference} onChange={(e) => setCaptainPreference(e.target.value)}>
                                                            <motion.option value={null}>Select An Option</motion.option>
                                                            <motion.option value='yes'>Yes</motion.option>
                                                            <motion.option value='no'>No</motion.option>
                                                        </motion.select>
                                                    </motion.div>
                                                    <motion.div className='relative mt-6' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                        <label className={labelClass}>Captain Nomination (Optional)</label>
                                                        <motion.input className={inputClass} animate={borderAnimate} whileFocus={borderFocus} transition={inputTransition} key='captainNomination' type='text' value={captainNomination} onChange={(e) => setCaptainNomination(e.target.value)}></motion.input>
                                                    </motion.div>
                                                    {spouse === true ?
                                                        <h1 className='w-full text-xs md:text-sm text-black text-center opacity-50 md:col-span-2'>**You will be automatically paired with your spouse in the team draft**</h1>
                                                    : null }
                                                </>
                                            : null }
                                        </>
                                    : null }
                                    {/* STEP 2 END */}
                                    {/* STEP 3 START */}
                                    {stepsCompleted === 2 ?
                                        <>
                                            <motion.div className='relative my-12' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className='absolute text-sm text-[#606060] -translate-y-8'>Self Skill Rating</label>
                                                <div className='absolute w-full h-3 rounded-full bg-gradient-to-r from-[#FF8084] via-[#FFD780] to-[#80FFAC] flex flex-row-reverse'>
                                                    <div className='h-3 rounded-full bg-[#606060]' style={{ width: fillerWidth[skillRating] }} />
                                                </div>
                                                <motion.input className='appearance-none absolute w-full h-3 rounded-full cursor-pointer bg-transparent [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:aspect-square [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#E6311D] [&::-moz-range-progress]:bg-[#E6311D]' key='slider' type='range' min={1} max={10} value={skillRating} onChange={(e) => setSkillRating(e.target.value)} />
                                                <span class='text-sm text-[#606060] absolute start-[1.5%] translate-y-6'>1</span>
                                                <span class='text-sm text-[#606060] absolute start-[12.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>2</span>
                                                <span class='text-sm text-[#606060] absolute start-[23%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>3</span>
                                                <span class='text-sm text-[#606060] absolute start-[33.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>4</span>
                                                <span class='text-sm text-[#606060] absolute start-[44.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>5</span>
                                                <span class='text-sm text-[#606060] absolute start-[55.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>6</span>
                                                <span class='text-sm text-[#606060] absolute start-[66%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>7</span>
                                                <span class='text-sm text-[#606060] absolute start-[76.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>8</span>
                                                <span class='text-sm text-[#606060] absolute start-[87.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>9</span>
                                                <span class='text-sm text-[#606060] absolute end-[0.5%] translate-y-6'>10</span>
                                            </motion.div>
                                            <motion.div className='relative my-12' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className='absolute text-sm text-[#606060] -translate-y-8'>Likelihood To Complete Challenge</label>
                                                <div className='absolute w-full h-3 rounded-full bg-gradient-to-r from-[#FF8084] via-[#FFD780] to-[#80FFAC] flex flex-row-reverse'>
                                                    <div className='h-3 rounded-full bg-[#606060]' style={{ width: fillerWidth[challengeCompletion] }} />
                                                </div>
                                                <motion.input className='appearance-none absolute w-full h-3 rounded-full cursor-pointer bg-transparent [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:aspect-square [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#E6311D] [&::-moz-range-progress]:bg-[#E6311D]' key='slider' type='range' min={1} max={10} value={challengeCompletion} onChange={(e) => setChallengeCompletion(e.target.value)} />
                                                <span class='text-sm text-[#606060] absolute start-[1.5%] translate-y-6'>1</span>
                                                <span class='text-sm text-[#606060] absolute start-[12.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>2</span>
                                                <span class='text-sm text-[#606060] absolute start-[23%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>3</span>
                                                <span class='text-sm text-[#606060] absolute start-[33.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>4</span>
                                                <span class='text-sm text-[#606060] absolute start-[44.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>5</span>
                                                <span class='text-sm text-[#606060] absolute start-[55.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>6</span>
                                                <span class='text-sm text-[#606060] absolute start-[66%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>7</span>
                                                <span class='text-sm text-[#606060] absolute start-[76.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>8</span>
                                                <span class='text-sm text-[#606060] absolute start-[87.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>9</span>
                                                <span class='text-sm text-[#606060] absolute end-[0.5%] translate-y-6'>10</span>
                                            </motion.div>
                                            <motion.div className='relative my-12' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>
                                                <label className='absolute text-sm text-[#606060] -translate-y-8'>Determination To Get MAX Points</label>
                                                <div className='absolute w-full h-3 rounded-full bg-gradient-to-r from-[#FF8084] via-[#FFD780] to-[#80FFAC] flex flex-row-reverse'>
                                                    <div className='h-3 rounded-full bg-[#606060]' style={{ width: fillerWidth[maxPoints] }} />
                                                </div>
                                                <motion.input className='appearance-none absolute w-full h-3 rounded-full cursor-pointer bg-transparent [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:aspect-square [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#E6311D] [&::-moz-range-progress]:bg-[#E6311D]' key='slider' type='range' min={1} max={10} value={maxPoints} onChange={(e) => setMaxPoints(e.target.value)} />
                                                <span class='text-sm text-[#606060] absolute start-[1.5%] translate-y-6'>1</span>
                                                <span class='text-sm text-[#606060] absolute start-[12.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>2</span>
                                                <span class='text-sm text-[#606060] absolute start-[23%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>3</span>
                                                <span class='text-sm text-[#606060] absolute start-[33.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>4</span>
                                                <span class='text-sm text-[#606060] absolute start-[44.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>5</span>
                                                <span class='text-sm text-[#606060] absolute start-[55.25%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>6</span>
                                                <span class='text-sm text-[#606060] absolute start-[66%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>7</span>
                                                <span class='text-sm text-[#606060] absolute start-[76.75%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>8</span>
                                                <span class='text-sm text-[#606060] absolute start-[87.5%] -translate-x-1/2 rtl:translate-x-1/2 translate-y-6'>9</span>
                                                <span class='text-sm text-[#606060] absolute end-[0.5%] translate-y-6'>10</span>
                                            </motion.div>
                                            {/* TIER 3: 3-11 TOTAL, TIER 2: 12-21 TOTAL, TIER 1: 22-30 TOTAL */}
                                        </>
                                    : null }
                                    {/* STEP 3 END */}
                                    {/* END SCREEN START */}
                                    {stepsCompleted === 3 ?
                                        <>
                                            <motion.h1 className='text-5xl font-black text-center md:col-span-2 text-[#E6311D]' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>SUCCESS!</motion.h1>
                                            <motion.h2 className='text-lg font-base text-center md:col-span-2 text-[#606060]' initial={inputInitial} animate={inputAnimate} exit={inputExit} transition={inputTransition}>You have been registered for Walk This Whey! You will be automatically redirected shortly.</motion.h2>
                                        </>
                                    : null }
                                    {/* END SCREEN END */}
                                </motion.div>
                            </motion.div>
                            {/* CONTENT END */}
                            {/* BUTTONS START */}
                            <motion.div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 place-content-between'>
                                <motion.button className='w-full md:w-56 max-w-full h-14 md:h-16 border-[#E6311D] rounded-2xl uppercase text-[#E6311D] focus:outline-none' animate={{ borderWidth: 2, fontSize: '21px', fontWeight: 400 }} whileHover={{ borderWidth: 4, fontSize: '22px', fontWeight: 600 }} whileFocus={{ borderWidth: 4, fontSize: '22px', fontWeight: 600 }} onClick={() => {
                                    setStepsCompleted(stepsCompleted === 0 ? 0 : stepsCompleted - 1)
                                    
                                    if (stepsCompleted === 0) {
                                        setWhatIsVisible('auth');
                                    }
                                }}>
                                    Back
                                </motion.button>
                                {stepsCompleted < 3 ?
                                    <motion.button className='w-full md:w-56 max-w-full h-14 md:h-16 justify-self-end bg-clip-padding rounded-2xl uppercase text-[#FFFFFF] focus:outline-none' animate={{ borderWidth: 0, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '21px', fontWeight: 400 }} whileHover={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '22px', fontWeight: 600 }} whileFocus={{ borderWidth: 4, borderColor: '#E6311D80', backgroundColor: '#E6311D', fontSize: '22px', fontWeight: 600 }} onClick={() => {
                                        if (stepsCompleted === 2) {
                                            register();
                                        }

                                        setStepsCompleted(stepsCompleted === 3 ? 3 : stepsCompleted + 1);
                                    }}>
                                        {stepsCompleted === 2 ? 'Submit' : 'Continue'}
                                    </motion.button>
                                : null }
                            </motion.div>
                            {/* BUTTONS END */}
                        </motion.div>
                    : null }
                    {/* REGISTER END */}
                    {/* {window.innerWidth < 768 ?
                        <h1 className='fixed bottom-0 mb-5 text-md font-medium text-[#FFFFFF] text-center uppercase underline underline-offset-4 cursor-pointer' onClick={() => window.open('/rules', '_blank')}>Click Here To View The Challenge Rules</h1>
                    : null } */}
                </motion.div>
                {/* CONTAINER END */}
            </div>
        </div>
    );
}

export default Auth;