import { useEffect, useState } from 'react';
import { db, auth } from '../FirebaseConfig.js';
import { doc, collection, getDoc, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { motion } from 'framer-motion';

import Loader from './Loader.js';
import { Link } from 'react-router-dom';

function Admin() {
    // Global Consts
    const [isLoading, setIsLoading] = useState(true);
    const [readyToLoad, setReadyToLoad] = useState(null);

    const [individuals, setIndividuals] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [teams, setTeams] = useState([]);

    const captainsList = {
        '2JX6WYcahPV2O81DISL4zZCV4mX2': `Brittney Ward's Team`,
        'FHgBwp14eJaK2kbBGZuvL1DqzAF3': `Jeff Bates' Team`,
        'Kks0WE5wATYQbopCUnXvckezqw63': `Zach Zollo's Team`,
        'P52Zr0abd4Y5p3RSEhxq3bvD3H62': `Dustin Stradley's Team`,
        'PkSPV44VFtQL6qcolWnnosgwEzx2': `Chelsea Reynolds' Team`,
        'QNMFxN5Pz1QdMiSU55oGNNH1mS83': `Angel Carpenter's Team`,
        'UXMe93DR7NWiJ7Mquslrpzcu9Bq2': `Johnny Merkel's Team`,
        'WBih20AnRJfTGYXZgqoU0yAO4Ui2': `Savannah Lopez's Team`,
        'XUwtpSB7cVN6eWn0Wn8UHNoKZkj2': `Chris Everett's Team`,
        'hDxL5rPwZ2U5n285hAWmaIL8nfj1': `Stephen Sheppard's Team`,
        'j0mJboPp8jN5Ky4nNtail8n4csT2': `Colleen Tunis' Team`,
        'jSA6REPTNMcfNQZDyc4QV3XGy303': `Ray Harper's Team`,
        'kRfDBbZCa9bpITBtzUqsNgnQy083': `Haley Hayaski's Team`,
        'smTFLmYvOWPqbCdlIFnfFzEPC3D2': `Claude Thomas' Team`,
        'vr0Oz90uRJgAPhWoCNbROwcnW9m1': `Mait Hudson's Team`,
        'w8T7j9hEfqYMthEzhLYps6g6xXI2': `Bernie Meyer's Team`,
        'wuCl2AULb2aE2Jhi5zOZ6sUYVPD3': `Ryan Eustace's Team`,
        'xjiZlI6ZZrdi7paCEexHmwjd1042': `Cami Crook's Team`,
        'ycHyyb4lt0WZzNnU8nWRXoN4bOg1': `Corey Pruitt's Team`,
    }

    var number = 0;

    async function checkAdmin(uid) {
        const dbRef = doc(db, `userCollection/${uid}/challengeData/walkThisWhey`);
        const dbSnap = await getDoc(dbRef);
        
        if (!dbSnap.exists || !dbSnap.data()?.isAdmin) {
            window.location.replace('/login');
            setIsLoading(false);
            return
        }

        setReadyToLoad(dbSnap.data().isAdmin);
        setIsLoading(false);
    }

    useEffect(() => {
        const getData = () => {
            onSnapshot(query(collection(db, `challengeCollection/walkThisWhey/users`), where('participationType', '==', 'individual')), (snapshot) => {
                let array = snapshot.docs.map(async (mainRes) => {
                    const mainData = { ...mainRes.data(), id: mainRes.id };

                    const userEmails = await new Promise(async (resolve) => {
                        const userRef = doc(db, `userCollection/${mainRes.id}`);
                        await getDoc(userRef)
                        .then(async (user) => {
                            resolve(user.data()?.email || '');
                        });
                    });

                    const didComplete = await new Promise(async (resolve) => {
                        const userRef = doc(db, `challengeCollection/walkThisWhey/users/${mainRes.id}/activity/points`);
                        await getDoc(userRef)
                        .then(async (user) => {
                            const userData = user.data();
                            if (userData?.walk >= 28 && userData?.wait >= 28 && userData?.weight >= 18 && userData?.whey >= 28) {
                                resolve('COMPLETED');
                            } else {
                                resolve('');
                            }
                        });
                    });

                    return { ...mainData, email: userEmails, completion: didComplete };
                });
                
                Promise.all(array).then((resultArray) => {
                    setIndividuals(resultArray);
                });
            });

            onSnapshot(query(collection(db, `challengeCollection/walkThisWhey/users`), where('participationType', '==', 'team')), (snapshot) => {
                let array = snapshot.docs.map(async (mainRes) => {
                    const mainData = { ...mainRes.data(), id: mainRes.id };

                    const userEmails = await new Promise(async (resolve) => {
                        const userRef = doc(db, `userCollection/${mainRes.id}`);
                        await getDoc(userRef)
                        .then(async (user) => {
                            resolve(user.data()?.email || '');
                        });
                    });

                    const didComplete = await new Promise(async (resolve) => {
                        const userRef = doc(db, `challengeCollection/walkThisWhey/users/${mainRes.id}/activity/points`);
                        await getDoc(userRef)
                        .then(async (user) => {
                            const userData = user.data();
                            if (userData?.walk >= 28 && userData?.wait >= 28 && userData?.weight >= 18 && userData?.whey >= 28) {
                                resolve('COMPLETED');
                            } else {
                                resolve('');
                            }
                        });
                    });

                    return { ...mainData, email: userEmails, completion: didComplete };
                });
                
                Promise.all(array).then((resultArray) => {
                    resultArray.sort((a, b) => {
                        return a.team?.localeCompare(b.team);
                    });

                    setTeamMembers(resultArray);
                });
            });

            onSnapshot(collection(db, `challengeCollection/walkThisWhey/teams`), (snapshot) => {
                setTeams(snapshot.docs.map((res) => ({ ...res.data(), id: res.id })));
            });
        }

        return () => {
            getData();
        }
    }, [readyToLoad]);

    useEffect(() => {
        const checkState = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                await checkAdmin(currentUser.uid);
            } else {
                window.location.replace('/login');
                setIsLoading(false);
                return
            }
        })

        return () => {
            checkState();
        }
    }, []);

    function tableToCSV() {
        var csv_data = [];

        var rows = document.getElementsByTagName('tr');
        for (var i = 0; i < rows.length; i++) {
            var cols = rows[i].querySelectorAll('td,th');

            var csvrow = [];
            for (var j = 0; j < cols.length; j++) {
                csvrow.push(cols[j].innerHTML);
            }

            csv_data.push(csvrow.join(','));
        }

        csv_data = csv_data.join('\n');

        downloadCSVFile(csv_data);
    }

    function downloadCSVFile(csv_data) {
        var CSVFile = new Blob([csv_data], {
            type: 'text/csv'
        });

        var temp_link = document.createElement('a');

        temp_link.download = 'walkThisWhey_Users.csv';
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        temp_link.style.display = 'none';
        document.body.appendChild(temp_link);

        temp_link.click();
        document.body.removeChild(temp_link);
    }

    // isLoading
    const LoadingOverlay = ({ isLoading }) => (
        <motion.div initial={ false } animate={{ opacity: isLoading ? 1 : 0 }} transition={{ duration: 0.2 }} style={{ pointerEvents: isLoading ? 'auto' : 'none' }} className='fixed top-0 left-0 w-screen h-screen m-0 flex justify-center items-center bg-[#00000080]'>
            <Loader />
            <Link to='/' />
        </motion.div>
    )

    return (
        <div className='w-screen h-screen'>
            <LoadingOverlay isLoading={isLoading} />
            <button className='my-5 py-5 px-16 rounded-2xl bg-orange-400 text-white text-xl font-black uppercase' onClick={() => tableToCSV()}>Download to CSV</button>

            <table className='mb-5 border-solid border border-collapse border-black'>
                <tr className='border-solid border border-collapse border-black'>
                    <th className='border-solid border border-collapse border-black'>Team Captain</th>
                    <th className='border-solid border border-collapse border-black'>Team Name</th>
                    <th className='border-solid border border-collapse border-black'># of Members</th>
                    <th className='border-solid border border-collapse border-black'>Total Points</th>
                </tr>
                {teams.map((dat) => {
                    return (
                        <tr key={dat.id} className='border-solid border border-collapse border-black'>
                            <td className='border-solid border border-collapse border-black'>{dat.captainName}</td>
                            <td className='border-solid border border-collapse border-black'>{dat.teamName}</td>
                            <td className='border-solid border border-collapse border-black'>{dat.members}</td>
                            <td className='border-solid border border-collapse border-black'>{dat.total}</td>
                        </tr>
                    )
                })}
            </table>

            <table className='border-solid border border-collapse border-black'>
                <tr className='border-solid border border-collapse border-black'>
                    <th className='border-solid border border-collapse border-black'>#</th>
                    <th className='border-solid border border-collapse border-black'>Name</th>
                    <th className='border-solid border border-collapse border-black'>Email</th>
                    <th className='border-solid border border-collapse border-black'>Type</th>
                    <th className='border-solid border border-collapse border-black'>Team</th>
                    <th className='border-solid border border-collapse border-black'>Shirt Size</th>
                    <th className='border-solid border border-collapse border-black'>Completion Status</th>
                </tr>
                {individuals.map((soloDat) => {
                    number = number + 1;

                    return (
                        <tr key={soloDat.id} className='border-solid border border-collapse border-black'>
                            <td className='border-solid border border-collapse border-black'>{number}</td>
                            <td className='border-solid border border-collapse border-black'>{soloDat.firstName} {soloDat.lastName}</td>
                            <td className='border-solid border border-collapse border-black'>{soloDat.email}</td>
                            <td className='border-solid border border-collapse border-black'>Individual Competition</td>
                            <td className='border-solid border border-collapse border-black'></td>
                            <td className='border-solid border border-collapse border-black'></td>
                            <td className='border-solid border border-collapse border-black'>{soloDat.completion}</td>
                        </tr>
                    )
                })}
                {teamMembers.map((teamDat) => {
                    number = number + 1;

                    return (
                        <tr key={teamDat.id} className='border-solid border border-collapse border-black'>
                            <td className='border-solid border border-collapse border-black'>{number}</td>
                            <td className='border-solid border border-collapse border-black'>{teamDat.firstName} {teamDat.lastName}</td>
                            <td className='border-solid border border-collapse border-black'>{teamDat.email}</td>
                            <td className='border-solid border border-collapse border-black'>Team Competition</td>
                            <td className='border-solid border border-collapse border-black font-semibold'>{captainsList[teamDat.team]}</td>
                            <td className='border-solid border border-collapse border-black'>{teamDat.shirtSize}</td>
                            <td className='border-solid border border-collapse border-black'>{teamDat.completion}</td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default Admin;