import SRC_poster from '../Assets/20231121_ADMI_eFit_WalkThisWay_Splash_FINAL.jpg';
import SRC_logo from '../Assets/Logo@3x.png';
import SRC_spreadsheet from '../Assets/Points_Spreadsheet.jpeg';

function Rules() {
    return(
        <div className='w-screen overflow-y-auto flex flex-col justify-center items-center text-center'>
            <div className='w-[95%] md:max-w-[75%] lg:max-w-[50%] my-12 flex flex-col justify-center items-center text-[#202020]'>
                <img className='w-3/4 md:w-1/2 lg:w-1/4 m-auto mb-8' src={SRC_poster} alt="textImg" />

                <h1 className='w-full mb-4 text-md font-medium text-center'>Alright, alright, alright! We're kicking off 2024 with an amazing opportunity to prioritize our spiritual, physical and mental health as a staff in our newest fitness challenge, WALK THIS WHEY. This challenge isn't about extremes but designed to help build and cultivate healthy lifestyle habits that we hope will carry on throughout the year. And it's a way to honor and care for the bodies God has entrusted to us.</h1>
                <h1 className='w-full mb-8 text-md font-medium text-center'>This challenge is a great opportunity for some to get started, a chance to establish consistency and set a great tone for the year. And for some already on this journey, it's a great chance to encourage and bring others along...and even lead a team. Join our team challenge today and let's get better together!</h1>

                <div className='w-full mb-4 text-left'>
                    <h2 className='text-xl font-black'>TIMELINE</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li>Thursday, January 4 to Monday, February 12 (40 days)</li>
                        <li>Registration opens on December 5. Firm team registration deadline on Monday, December 18 (2 weeks)</li>
                        <li>Team Draft Night on Tuesday, December 19</li>
                    </ul>

                    <h2 className='text-xl font-black'>TEAMS/INDIVIDUALS</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li>Staff & Staff Spouses sign up as individuals and will be drafted to a team by a Team Captain.</li>
                        <li>We're better together so join our team challenge! Challenge shirts are <span className='underline underline-offset-2'>provided</span> for all that join a team.</li>
                        <li>Teams have the option to release 1 team member at the 20 day mark (if not participating) but will not be eligible to win first place if so.</li>
                        <li>There is an option to participate as an individual if chosen or someone signs up after the team deadline.</li>
                    </ul>

                    <h2 className='text-xl font-black'>FOUR COMPONENTS OF WTW</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-decimal'>
                        <li><b>WALK</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>WALK 10,000 steps per day - 5 days required and up to 7 days per week</li>
                            <ul className='ml-6 md:ml-12 list-disc'>
                                <li>Cumulative total of 50,000 steps required each week</li>
                                <li>Can use days 6 & 7 to achieve 50,000 steps</li>
                                <li>10,000 max steps can be logged per day</li>
                                <li>E.g. 7,143 steps per day x 7 days per week would give the required 50,000 steps</li>
                                <ul className='ml-6 md:ml-12 list-disc'>
                                    <li>In this example, you would earn 0.7143 points per day</li>
                                </ul>
                            </ul>
                            <li>Steps can be tracked with a smartphone, smartwatch, fitbit device, pedometer, etc.</li>
                        </ul>
                        <li><b>WEIGHT</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>WEIGHT Training - 3 days required and up to 6 days per week</li>
                            <li>This can include body weight strength training but traditional cardio exercises and workouts do not count. Ideally individuals include some external resistance as part of their weight training.</li>
                            <li>The goal is at least 30-45 minutes of challenging weight training. If you already weight train 3 days per week, you should shoot for 4+ days per week.</li>
                        </ul>
                        <li><b>WAIT</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>WAIT upon the Lord - 5 days required and up to 7 days per week</li>
                            <li>Here are options (looking for 20 minutes of dedicated Bible study devotion time):</li>
                            <ul className='ml-6 md:ml-12 list-disc'>
                                <li>30 Days to Resilient Plan in the <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('https://www.pauseapp.com/?gclid=CjwKCAiA0syqBhBxEiwAeNx9N4-6JGQH-TgVHtN5Wvlxws2FNeDoqavYAgO-VGy91knyIWfe3R-lCRoCv1EQAvD_BwE', '_blank')}>One Minute Pause App</span></li>
                                <ul className='ml-6 md:ml-12 list-disc'>
                                    <li>Approximately 10 minutes in the morning and 10 minutes in the evening</li>
                                </ul>
                                <li>Or Elevation Bible Reading Plan Options: <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('https://drive.google.com/file/d/1lKPU5hFVapTjxgdJu4rvcbUu6O_TjnlK/view?usp=drive_link', '_blank')}>Gospels</span> & <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('https://drive.google.com/file/d/1LBaUeznFZfodoc03eYKWxz5YHXp4UJhA/view?usp=drive_link', '_blank')}>Paul's Letters</span></li>
                            </ul>
                        </ul>
                        <li><b>WHEY</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>WHEY protein - 5 days required and up to 7 days per week</li>
                            <li>Participants must eat at least 0.7 (or more) grams of protein per pound of body weight OR their goal body weight if you are not close to your current goal weight. Reach out to <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck</span> for help with this.</li>
                            <li>*The goal body weight must be a realistic, achievable number (not that high school number you're unrealistically hoping for).</li>
                            <li>Protein can come from any source (not just whey), so plant based and other sources are allowed.</li>
                        </ul>
                    </ul>

                    <h2 className='text-xl font-black'>POINTS</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li><b>INDIVIDUAL GOAL</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Each component logged earns up to 1 point per day except steps, which are logged as a decimal if less than 10,000 steps.</li>
                            <li>Must earn 102 out of 155 possible points to complete the challenge.</li>
                            <li>Breakdown of required points vs total possible points per week.</li>
                        </ul>
                        <img className='w-full md:w-3/4 lg:w-1/2 mx-auto my-4' src={SRC_spreadsheet} alt="pointsBreakdown" />
                        <li><b>TEAM GOAL</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>The top 5 teams with the highest point total based on an average per person will win team prizes.</li>
                        </ul>
                    </ul>

                    {/* <h2 className='text-xl font-black'>PRIZES</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li><b>INDIVIDUAL COMPLETION PRIZE OPTIONS</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Branded Full Zip Up Windbreaker Jacket</li>
                            <li>Or Shopify credit option for choice of protein/supplements, Clean Eatz, or Elevation apparel</li>
                        </ul>
                        <li><b>TOP 5 WINNING TEAM PRIZE GIFT CARDS/STORE CREDITS</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>First Place Options: $250</li>
                            <ul className='ml-6 md:ml-12 list-disc'>
                                <li>Walking/Workout Shoes/Gear from Adidas or Dick's</li>
                                <li><span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('https://www.apple.com/apple-watch-se/', '_blank')}>Apple Watch SE</span> (Apple)</li>
                            </ul>
                            <li>Second Place: $150</li>
                            <li>Third Place: $100</li>
                            <li>Fourth Place: $75</li>
                            <li>Fifth Place: $50</li>
                            <li>*While all participant's points count toward their team scores, completing the individual challenge is required to be eligible for their team prize.</li>
                        </ul>
                    </ul> */}

                    <h2 className='text-xl font-black'>PRIZES</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li>Earn branded swag for completing the challenge.</li>
                        <li>Individual and Team Prize details will be listed in the app.</li>
                    </ul>

                    <h2 className='text-xl font-black'>LOGGING</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-normal list-disc'>
                        <li>Logging will take place on the challenge website: <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('https://elevation.fit/', '_blank')}>https://elevation.fit</span>.</li>
                        <li>24 hour grace period to log your points.</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>E.g. Individuals have until 11:59PM on Wednesday to enter your points earned on Tuesday before their points expire and will be unable to log/get credit for them.</li>
                            <li>Category point totals and remaining points needed will be displayed on the challenge website.</li>
                            <li>Push notification reminder options will be available.</li>
                        </ul>
                    </ul>
                </div>
                <h2 className='mb-8 text-base font-semibold'>Contact <span className='underline font-bold cursor-pointer text-[#E6311D]' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck Buhler</span> if you have any questions or need assistance.</h2>

                <img className='w-12 m-auto mb-16' src={SRC_logo} alt="logoImg" />
            </div>
        </div>
    )
}

export default Rules;