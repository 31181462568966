import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBFNMkhM9mGuTJIhm2BdLZv6wLDMY1mGcM",
  authDomain: "walkthiswhey.web.app",
  projectId: "efit-ba871",
  storageBucket: "efit-ba871.appspot.com",
  messagingSenderId: "916960279282",
  appId: "1:916960279282:web:bec66522cdb7c8e2492e6b",
  measurementId: "G-GJBQ10JKKT"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBCQmyE3mKA7d-2Qpf1JVIkEXWSHaLe23w",
//   authDomain: "misc-project-46536.firebaseapp.com",
//   projectId: "misc-project-46536",
//   storageBucket: "misc-project-46536.appspot.com",
//   messagingSenderId: "160785680764",
//   appId: "1:160785680764:web:7ba5a504c1a6887e9e7cf9"
// };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
}