import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseConfig.js';
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { motion } from 'framer-motion';
import EmojiPicker, { Emoji } from 'emoji-picker-react';

import Loader from './Loader.js';
import { Link } from 'react-router-dom';
import ConfettiGenerator from 'confetti-js';

import walkFill from '../Assets/icons/walk.png';
import walkTransparent from '../Assets/icons/walk-transparent.png';
import weightFill from '../Assets/icons/weight.png';
import weightTransparent from '../Assets/icons/weight-transparent.png';
import waitFill from '../Assets/icons/wait.png';
import waitTransparent from '../Assets/icons/wait-transparent.png';
import wheyFill from '../Assets/icons/whey.png';
import wheyTransparent from '../Assets/icons/whey-transparent.png';
// import notificationFill from '../Assets/icons/notification.png';
// import notificationTransparent from '../Assets/icons/notification-transparent.png';
import addEmojiIcon from '../Assets/icons/add-emoji.png';
import checkIcon from '../Assets/icons/check.png';
import logArrow from '../Assets/icons/arrow-point-to-right.png';
import statsArrow from '../Assets/icons/up-arrow.png';

const isMobile = window.innerWidth < 768;
const containerAnimation = isMobile ? null : {
    initial: { scale: 0.25, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    transition: { duration: 0.65 },
};
const blockAnimation = isMobile ? null : {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
}

const bgRandomizer = {
    1: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2023/20231222-Christmas/BAL/WORSHIP-EXPERIENCE/i-qg5L75Z/0/4b038a34/X4/20231222SB_BAL_2070-X4.jpg')]",
    2: "bg-[url('https://photos.smugmug.com/Worship-Department/2020/Live-Recording-Graves-Into-Gardens/Live-Recording/i-m236qDk/0/58c74c21/X4/20200115_LiveRecording_GIGA-3969-X4.jpg')]",
    3: "bg-[url('https://photos.smugmug.com/ELEVATION-NIGHTS/2022/AZ-Glendale/i-r4ng7Pb/0/27c87273/X4/20221025_DS-77-X4.jpg')]",
    4: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2021/NEW-YEARS-PRAISE-PARTY/BAL/Worship-Experiences/i-xcGkB22/0/2a340681/X4/12312021_BAL_BLP-2029-X4.jpg')]",
    5: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2022/20221222-CHRISTMAS-EXPERIENCE/20221223-A-PROMISE-KEPT/BAL/WORSHIP-EXPERIENCES/i-CGrjdn6/0/257e5fd8/X4/12232022_APromiseKept_BAL_PB-2028-X4.jpg')]",
    6: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2023/20230407-GOOD-FRIDAY-PSF/BAL/WORSHIP-EXPERIENCE/i-4DRZfWt/0/55a679df/X4/040723_GoodFriday_PB-34-X4.jpg')]",
    7: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2023/20230409-EASTER-PSF/BAL/WORSHIP-EXPERIENCES/i-bmZ9T79/0/d5bdea3e/X4/040923_BLR_BAL-4013-X4.jpg')]",
    8: "bg-[url('https://photos.smugmug.com/ESTUDENTS/2022/RHYTHM-NIGHTS-AUG-DEC/SEPTEMBER/BAL/WORSHIP-EXPERIENCE/i-RQhvq2w/0/65ef8633/X4/20220928%40BLUEAMBERPHOTO_RHYTHMNIGHT_BAL_-3077-X4.jpg')]",
    9: "bg-[url('https://photos.smugmug.com/Worship-Department/2023/Night-of-Worship/i-vgJPqS6/0/7e8396d3/X4/LR_BLR-1010-X4.jpg')]",
    10: "bg-[url('https://photos.smugmug.com/SERMONSERIESGALLERY/2023/20231224-Christmas/BAL/WORSHIP-EXPERIENCE/i-qjHNMtT/0/934776b4/X4/20231224%40Blueamberphoto_BAL_Christmas%20Experience_-3054-X4.jpg')]",
};

const bgToPick = bgRandomizer[Math.floor((Math.random() * 10) + 1)];
// const bgToPick = bgRandomizer[1];
const currentDate = new Date()

let version = null;
const getVersion = () => {
    onSnapshot(doc(db, 'challengeCollection/walkThisWhey'), (snapshot) => {
        // console.log(version);
        let tempVersion = snapshot.data()?.version || 0;

        if (version === null) {
            version = tempVersion;
        }

        if (version !== tempVersion) {
            window.location.reload();
        }

        // console.log(tempVersion);
    })
}

getVersion();

function Home() {
    // Global Consts
    const [isLoading, setIsLoading] = useState(true);
    // const [readyToLoad, setReadyToLoad] = useState(false);
    const [currentAuthUser, setCurrentAuthUser] = useState(null);

    const time = new Date().getHours();

    // Alert Consts
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        text: null,
        errorText: null,
        subCategory: null,
        urgency: null,
        description: null,
        ticketButtonVisible: false,
    });

    const [globalDate, setGlobalDate] = useState('');
    const [dateActive, setDateActive] = useState('yesterday');
    const [currentWeekStart, setCurrentWeekStart] = useState('');
    const [currentWeekEnd, setCurrentWeekEnd] = useState('');
    const [lastWeekStart, setlastWeekStart] = useState('');
    const [lastWeekEnd, setLastWeekEnd] = useState('');

    const [recordGlobal, setRecordGlobal] = useState('');
    const [walkEntryVisible, setWalkEntryVisible] = useState(false);
    const [startingWalk, setStartingWalk] = useState(0);
    const [walkGlobal, setWalkGlobal] = useState(0);
    const [weightAvailable, setWeightAvailable] = useState(true);
    const [startingWeight, setStartingWeight] = useState(0);
    const [weightGlobal, setWeightGlobal] = useState(0);
    const [startingWait, setStartingWait] = useState(0);
    const [waitGlobal, setWaitGlobal] = useState(0);
    const [startingWhey, setStartingWhey] = useState(0);
    const [wheyGlobal, setWheyGlobal] = useState(0);
    const [messageGlobal, setMessageGlobal] = useState('');

    const [userData, setUserData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [pointsData, setPointsData] = useState([]);
    const [allActivityData, setAllActivityData] = useState([]);
    const [individualPodium, setIndividualPodium] = useState([]);
    const [teamPodium, setTeamPodium] = useState([]);
    // const [notificationData, setNotificationData] = useState([1, 2, 3]);

    const [individualLeaderboardVisible, setIndividualLeaderboardVisible] = useState(false);
    const [fullIndividualLeaderboard, setFullIndividualLeaderboard] = useState([]);
    const [teamLeaderboardVisible, setTeamLeaderboardVisible] = useState(false);
    const [fullTeamLeaderboard, setFullTeamLeaderboard] = useState([]);

    const [emojiPickerVisible, setEmojiPickerVisible] = useState(null);
    // const [notificationsVisible, setNotificationsVisible] = useState(false);
    const [statsVisible, setStatsVisible] = useState(false);

    async function checkRegistration() {
        console.log('Checking Registration');
        const checkRegistrationFunction = httpsCallable(functions, 'checkChallengeRegistration');
        await checkRegistrationFunction()
        .then((result) => {
            if (result.data !== 'Registered') {
                window.location.replace('/login');
                return
            }
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Could not verify your registration status. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Account Access',
                urgency: 'Medium',
                description: `*** Error checking registration status via Firebase Function (checkChallengeRegistration) --- Error Message: '${err}' --- Location: /login ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return
        });
    }

    // async function checkAdmin(uid) {
    //     const dbRef = doc(db, `userCollection/${uid}/challengeData/walkThisWhey`);
    //     const dbSnap = await getDoc(dbRef);
        
    //     if (!dbSnap.exists || !dbSnap.data()?.isAdmin) {
    //         window.location.replace('/');
    //         setIsLoading(false);
    //         return
    //     }

    //     setReadyToLoad(dbSnap.data().isAdmin);
    // }

    useEffect(() => {
        const checkState = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                await checkRegistration();
                setCurrentAuthUser(currentUser);
            } else {
                // THIS ACTIVATES IF USER IS NOT LOGGED IN
                setIsLoading(false);
                window.location.replace('/login');
                return
            }
        })

        return () => {
            checkState();
        }
    }, []);

    useEffect(() => {
        setGlobalDate(getDate('today'));

        const getDates = onSnapshot(doc(db, 'challengeCollection/walkThisWhey'), (snapshot) => {
            setCurrentWeekStart(snapshot.data()?.currentWeekStart);
            setCurrentWeekEnd(snapshot.data()?.currentWeekEnd);
            setlastWeekStart(snapshot.data()?.lastWeekStart);
            setLastWeekEnd(snapshot.data()?.lastWeekEnd);
        })

        const getMasterData = onSnapshot(doc(db, `challengeCollection/walkThisWhey/users/${currentAuthUser?.uid}`), (snapshot) => {
            setUserData(snapshot.data());

            if (snapshot.data()?.team !== '' && snapshot.data()?.team !== undefined && snapshot.data()?.team !== null) {
                onSnapshot(doc(db, `challengeCollection/walkThisWhey/teams/${snapshot.data()?.team}`), (snapshot) => {
                    setTeamData(snapshot.data());
                });
            }
        });

        const getIndividualLeaderboard = onSnapshot(query(collection(db, 'challengeCollection/walkThisWhey/leaderboard'), orderBy('total', 'desc'), limit(5)), (snapshot) => {
            let place = 1;
            let array = [];

            snapshot.docs.forEach((res) => {
                if (res.data()?.total === 0) {
                    const dataToPost = {
                        total: 0,
                        displayName: `${place}`,
                        profilePic: '',
                        place: place,
                    };

                    array.push(dataToPost);
                } else {
                    const dataToPost = {
                        total: res.data()?.total || 0,
                        displayName: res.data()?.displayName || '',
                        profilePic: res.data()?.profilePic || '',
                        place: place,
                    };

                    array.push(dataToPost);
                }

                place++;
            });

            const miniFunction = () => {
                const dataToPost = {
                    total: 0,
                    displayName: `${place}`,
                    profilePic: '',
                    place: place,
                };

                array.push(dataToPost);
                place++;

                if (array.length !== 5) {
                    miniFunction();
                }
            }

            if (array.length !== 5) {
                miniFunction();
            }

            setIndividualPodium(array);
        });

        const getTeamLeaderboard = onSnapshot(query(collection(db, 'challengeCollection/walkThisWhey/teams'), orderBy('average', 'desc'), limit(5)), (snapshot) => {
            let place = 1;
            let array = [];

            snapshot.docs.forEach((res) => {
                if (res.data()?.total === 0) {
                    const dataToPost = {
                        total: 0,
                        captainName: `${place}`,
                        profilePic: '',
                        teamName: '',
                        place: place,
                    };

                    array.push(dataToPost);
                } else {
                    const dataToPost = {
                        total: res.data()?.total || 0,
                        captainName: res.data()?.captainName || '',
                        profilePic: res.data()?.profilePic || '',
                        teamName: res.data()?.teamName || '',
                        place: place,
                    };

                    array.push(dataToPost);
                }

                place++;
            });

            setTeamPodium(array);
        });

        // let [clientVersion, setClientVersion] = useState(null); // move
        // const getSettings = onSnapshot(doc(db, `challengeCollection/walkThisWhey`), (snapshot) => {
        //     let {serverVersion = null} = snapshot.data();
        //     if (clientVersion !== null && clientVersion !== serverVersion) {
        //         //window.location.reload();
        //     } else {
        //         setClientVersion(serverVersion);
        //     }
        // })

        const getPointsData = onSnapshot(doc(db, `challengeCollection/walkThisWhey/users/${currentAuthUser?.uid}/activity/points`), (snapshot) => {
            setPointsData(snapshot.data());
        });
        
        const getAllActivity = onSnapshot(query(collection(db, 'challengeCollection/walkThisWhey/allActivity'), orderBy('loggedAt', 'desc'), limit(50)), (snapshot) => {
            setAllActivityData(snapshot.docs.map((res) => ({ ...res.data(), id: res.id })));
        });

        return () => {
            getMasterData();
            getIndividualLeaderboard();
            getTeamLeaderboard();
            getPointsData();
            getAllActivity();
            getDates();
        }
    }, [currentAuthUser]);

    useEffect(() => {
        getLogData(dateActive);
    }, [currentWeekStart, lastWeekEnd, dateActive , userData]);

    function getLogData(date) {
        let dateToSearch = date;
        if (!dateToSearch || dateToSearch === undefined || dateToSearch === null || dateToSearch === '') {
            dateToSearch = 'today';
        }

        // console.log(dateToSearch);

        getDoc(doc(db, `challengeCollection/walkThisWhey/users/${currentAuthUser?.uid}/activity/${getDate(dateToSearch)}`))
        .then((snapshot) => {
            setRecordGlobal(snapshot.data()?.record || '');
            setMessageGlobal(snapshot.data()?.message || '');
            setWalkGlobal(snapshot.data()?.walk || 0);
            setWeightGlobal(snapshot.data()?.weight || 0);
            setWaitGlobal(snapshot.data()?.wait || 0);
            setWheyGlobal(snapshot.data()?.whey || 0);
            setStartingWalk(snapshot.data()?.walk || 0);
            setStartingWeight(snapshot.data()?.weight || 0);
            setStartingWait(snapshot.data()?.wait || 0);
            setStartingWhey(snapshot.data()?.whey || 0);

            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Could not get your activity data. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Servers / Websites',
                urgency: 'Medium',
                description: `*** Error getting activity data --- Error Message: '${err}' --- Location: / ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            return
        })

        let startDate = '';
        let endDate = '';
        if (getDate(dateToSearch) === lastWeekEnd) {
            startDate = lastWeekStart;
            endDate = getDate(`${dateToSearch}-1`);
        } else {
            startDate = currentWeekStart;
            endDate = getDate(`${dateToSearch}-1`);
        }

        // console.log(startDate, endDate);

        getDocs(query(collection(db, `challengeCollection/walkThisWhey/users/${currentAuthUser?.uid}/activity/`), where('date', '>=', startDate), where('date', '<=', endDate)))
        .then((results) => {
            if (results.empty) {
                setWeightAvailable(true);
            } else {
                let weightNum = 0;
                results.forEach((res) => {
                    if (res.data()?.weight === 1){
                        weightNum++;
                    }
                });

                if (weightNum < 6) {
                    setWeightAvailable(true);
                } else if (weightNum >= 6) {
                    setWeightAvailable(false);
                }
            }
        });
    }

    function getDate(date) {
        if (date === 'today') {
            const dateObj = new Date(new Date().toDateString());

            return `${dateObj.getFullYear()}-${dateObj.toLocaleString('default', { month: '2-digit' })}-${dateObj.toLocaleString('default', { day: '2-digit' })}`;
        } else if (date === 'yesterday' || date === 'today-1') {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1)
            const dateObj = new Date(yesterday.toDateString());

            return `${dateObj.getFullYear()}-${dateObj.toLocaleString('default', { month: '2-digit' })}-${dateObj.toLocaleString('default', { day: '2-digit' })}`;
        } else if (date === 'yesterday-1') {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 2)
            const dateObj = new Date(yesterday.toDateString());

            return `${dateObj.getFullYear()}-${dateObj.toLocaleString('default', { month: '2-digit' })}-${dateObj.toLocaleString('default', { day: '2-digit' })}`;
        } else if (date === 'week-today') {
            const today = new Date();
            const weekAgo = new Date(today);
            weekAgo.setDate(weekAgo.getDate() - 6);
            const dateObj = new Date(weekAgo.toDateString());

            return `${dateObj.getFullYear()}-${dateObj.toLocaleString('default', { month: '2-digit' })}-${dateObj.toLocaleString('default', { day: '2-digit' })}`;
        } else if (date === 'week-yesterday') {
            const today = new Date();
            const weekAgo = new Date(today);
            weekAgo.setDate(weekAgo.getDate() - 7);
            const dateObj = new Date(weekAgo.toDateString());

            return `${dateObj.getFullYear()}-${dateObj.toLocaleString('default', { month: '2-digit' })}-${dateObj.toLocaleString('default', { day: '2-digit' })}`;
        } else if (date === 'today-time') {
            return new Date().toISOString();
        } else if (date === 'yesterday-time') {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return new Date(yesterday).toISOString();
        }
    }

    async function getFullIndividualLeaderboard() {
        let results = await getDocs(query(collection(db, 'challengeCollection/walkThisWhey/leaderboard'), orderBy('total', 'desc')))
        let place = 1;
        let array = [];

        results.docs.forEach((res) => {
            array.push({
                displayName: res.data()?.displayName || '',
                profilePic: res.data()?.profilePic || '',
                total: res.data()?.total || 0,
                id: res.id,
                place: place,
            });

            place++;
        });

        setFullIndividualLeaderboard(array);

        setIsLoading(false);
    }

    async function getFullTeamLeaderboard() {
        let results = await getDocs(query(collection(db, 'challengeCollection/walkThisWhey/teams'), orderBy('average', 'desc')))

        let promises = results.docs.map(async (res, i) => {
            let membersResults = await getDocs(query(collection(db, `challengeCollection/walkThisWhey/teams/${res.id}/members`), orderBy('total', 'desc')))
            let subArray = membersResults.docs.map(memberDoc => memberDoc.data()).map((memberRes) => {
                return {
                    displayName: memberRes.displayName || '',
                    total: memberRes.total || 0,
                };
            });

            return {
                teamName: res.data()?.teamName || '',
                captainName: res.data()?.captainName || '',
                average: res.data()?.average || 0,
                total: res.data()?.total || 0,
                members: subArray,
                id: res.id,
                place: i+1,
            };
        });
        let array = await Promise.all(promises);

        setFullTeamLeaderboard(array);

        setIsLoading(false);
    }

    async function logData() {
        setIsLoading(true);

        if ((walkGlobal === 0 || walkGlobal === '') && weightGlobal === 0 && waitGlobal === 0 && wheyGlobal === 0) {
            setIsLoading(false);
            return
        }

        const dataToPost = {
            date: getDate(dateActive),
            time: getDate(`${dateActive}-time`),
            record: recordGlobal || '',
            displayName: userData?.displayName,
            profilePic: userData?.profilePic,
            team: userData?.team || '',
            // teamName: stuffHere,

            startingWalk: startingWalk,
            startingWeight: startingWeight,
            startingWait: startingWait,
            startingWhey: startingWhey,

            walk: walkGlobal === '' ? 0 : walkGlobal,
            weight: weightGlobal,
            wait: waitGlobal,
            whey: wheyGlobal,
            message: messageGlobal,
        };

        const postLogData = httpsCallable(functions, 'logActivity');
        await postLogData({ data: dataToPost })
        .then(() => {
            getLogData(dateActive);
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `Error logging activity. Please try again or submit a tech ticket.`,
                errorText: `Error Message: '${err}'`,
                subCategory: 'Servers / Websites',
                urgency: 'Medium',
                description: `*** Error logging user's activity via Firebase Function (logActivity) --- Error Message: '${err}' --- Location: / ***`,
                ticketButtonVisible: true,
            });
            setAlertVisible(true);
            console.log(err);
        });
    }

    async function addReaction(activityId, emojiId) {
        // setIsLoading(true);

        const setInfoData = {
            activity: activityId,
            emoji: emojiId,
        };

        const postReaction = httpsCallable(functions, 'addReaction');
        await postReaction({ data: setInfoData })
        .catch((err) => {
            setIsLoading(false);
            setAlertInfo({
                text: `We encountered an error. Please try again.`,
                errorText: null,
                subCategory: null,
                urgency: null,
                description: null,
                ticketButtonVisible: false,
            });
            setAlertVisible(true);
            console.log(err);
        });
    }

    // useEffect(() => {
    //     if (!statsVisible && !individualLeaderboardVisible && !teamLeaderboardVisible) {
    //         var confettiSettings = {
    //             target: 'confetti-canvas',
    //             max: isMobile ? 35 : 25,
    //             size: isMobile ? 1 : 2.5,
    //             animate: isMobile ? false : true,
    //             respawn: true,
    //             clock: 20,
    //             rotate: false,
    //         }
    
    //         const confetti = new ConfettiGenerator(confettiSettings);
    //         confetti.render();
    
    //         return () => confetti.clear();
    //     }
    // }, []);

    const english_ordinal_rules = new Intl.PluralRules('en', {type: 'ordinal'});
    const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    function ordinal(number, wanting) {
        const catergory = english_ordinal_rules.select((number));
        const suffix = suffixes[catergory];
        if (wanting === 'number') {
            return number;
        } else {
            return suffix;
        }
    }

    // isLoading
    const LoadingOverlay = ({ isLoading }) => (
        <motion.div initial={ false } animate={{ opacity: isLoading ? 1 : 0 }} transition={{ duration: 0.2 }} style={{ pointerEvents: isLoading ? 'auto' : 'none' }} className='fixed top-0 left-0 w-screen h-screen m-0 flex justify-center items-center bg-[#00000080] z-40'>
            <Loader />
            <Link to='/' />
        </motion.div>
    )

    return (
        <div className='w-screen h-screen'>
            <LoadingOverlay isLoading={isLoading} />
            <div className={`w-screen h-screen m-0 md:p-12 flex flex-col md:gap-5 justify-center items-center ${bgToPick} bg-center bg-cover bg-no-repeat`}>
                {alertVisible ?
                    <motion.div className='fixed top-0 left-0 w-screen h-screen bg-[#00000050] flex flex-col justify-center items-center z-50' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <motion.div className='w-full max-w-4xl rounded-3xl bg-[#FFFBFC] flex flex-col justify-center items-center md:m-5 p-5 gap-10' initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0, opacity: 0 }}>
                            <h1 className='text-2xl md:text-3xl font-bold uppercase text-center'>Uh Oh! We encountered an error!</h1>
                            <h2 className='w-full text-base md:text-lg font-normal'>{alertInfo.text}</h2>
                            {alertInfo.errorText ?
                                <h2 className='w-full text-base md:text-lg font-normal'>{alertInfo.errorText}</h2>
                            : null }
                            {/* BUTTONS START */}
                            <motion.div className='w-full h-full grid grid-cols-1 md:grid-cols-2 gap-5 place-content-between'>
                                <motion.button
                                    className='w-full md:w-64 max-w-full h-14 md:h-16 justify-self-start border-[#F71735] rounded-2xl uppercase text-[#F71735] focus:outline-none'
                                    animate={{ borderWidth: 2, fontSize: '21px', fontWeight: 400 }}
                                    whileHover={{ borderWidth: 4, fontSize: '22px', fontWeight: 600 }}
                                    whileFocus={{ borderWidth: 4, fontSize: '22px', fontWeight: 600 }}
                                    onClick={() => {
                                        setAlertVisible(false);
                                    }}
                                >Close</motion.button>
                                {alertInfo.ticketButtonVisible ?
                                    <motion.button
                                        className='w-full md:w-64 max-w-full h-14 md:h-16 justify-self-end uppercase text-white bg-clip-padding rounded-2xl focus:outline-none'
                                        animate={{ borderWidth: 0, borderColor: '#99B2AF80', backgroundColor: '#99B2AF', fontSize: '21px', fontWeight: 400 }}
                                        whileHover={{ borderWidth: 4, borderColor: '#99B2AF80', backgroundColor: '#99B2AF', fontSize: '22px', fontWeight: 600 }}
                                        whileFocus={{ borderWidth: 4, borderColor: '#99B2AF80', backgroundColor: '#99B2AF', fontSize: '22px', fontWeight: 600 }}
                                        onClick={() => {
                                            window.open(`https://staff.elevationchurch.org/support/tickets/new?category=Software%20/%20Development&sub_category=${encodeURI(`${alertInfo.subCategory}`)}&urgency=${encodeURI(`${alertInfo.urgency}`)}&subject=eFit%20%2D%20Staff%20Fitness%20Challenge&description=${encodeURI(`${alertInfo.description}`)}`, '_blank');
                                        }}
                                    >Submit Tech Ticket</motion.button>
                                : null }
                            </motion.div>
                            {/* BUTTONS END */}
                        </motion.div>
                    </motion.div>
                : null }
                {/* START MAIN CONTAINER */}
                <motion.div className='w-full h-full backdrop-blur-3xl bg-[#FFFFFF25] md:rounded-3xl md:border-[0.5px] md:border-[#FFFFFF50] md:shadow-2xl md:shadow-black flex flex-col-reverse md:flex-row' {...containerAnimation}>
                    {!statsVisible ?
                        <motion.div className='w-full h-full md:rounded-tl-3xl md:rounded-bl-3xl flex flex-col justify-start items-start px-5 pb-16 md:p-5 gap-5 overflow-y-scroll'>
                            <div className='w-full flex flex-row justify-center md:justify-between items-center'>
                                <div className='h-12 2xl:h-16 flex justify-center items-center'>
                                    {!isLoading && userData?.firstName !== undefined ?
                                        <h1 className='text-white text-3xl 2xl:text-4xl font-normal'>{time >= 0 && time <= 4 ? 'Good Night, ' : time >= 5 && time <= 8 ? 'Rise and Shine, ' : time >= 9 && time <= 12 ? 'Good Morning, ' : time >= 13 && time <= 16 ? 'Good Afternoon, ' : time >= 17 && time <= 21 ? 'Good Evening, ' : time >= 22 ? 'Good Night, ' : 'Hello, '}<span className='font-extrabold'>{userData?.firstName}</span></h1>
                                    :
                                        <h1 className='text-white text-3xl 2xl:text-4xl font-normal'>{time >= 0 && time <= 4 ? 'Good Night' : time >= 5 && time <= 8 ? 'Rise and Shine' : time >= 9 && time <= 12 ? 'Good Morning' : time >= 13 && time <= 16 ? 'Good Afternoon' : time >= 17 && time <= 21 ? 'Good Evening' : time >= 22 ? 'Good Night' : 'Hello There'}</h1>
                                    }
                                </div>
                            </div>
                            {individualLeaderboardVisible ?
                                <motion.div className='w-full backdrop-blur-3xl bg-[#FFFFFF25] md:border-[0.5px] md:border-[#FFFFFF50] md:shadow-2xl md:shadow-black rounded-2xl flex flex-col justify-start items-start p-3 gap-3 2xl:p-7 2xl:gap-7' {...blockAnimation} transition={{ delay: 0.2 }}>
                                    <div className='w-full flex flex-row justify-between items-center'>
                                        <div className='flex flex-row justify-center items-center gap-3'>
                                            <h1 className='text-lg 2xl:text-2xl font-bold mr-auto text-white'>Individual Leaderboard</h1>
                                            <h1 className='text-base 2xl:text-xl font-semibold text-white'>•</h1>
                                            <button className='text-sm 2xl:text-lg font-normal text-white opacity-75' onClick={() => {
                                                setIsLoading(true);
                                                getFullIndividualLeaderboard();
                                            }}>{isMobile ? 'Refresh' : 'Refresh Data'}</button>
                                        </div>
                                        <button className='text-lg 2xl:text-2xl font-normal text-white uppercase' onClick={() => {
                                            setIndividualLeaderboardVisible(false);
                                            setFullIndividualLeaderboard([]);
                                        }}>Back</button>
                                    </div>
                                    <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5'>
                                        {!isLoading && fullIndividualLeaderboard.length !== 0 ?
                                            fullIndividualLeaderboard.map((dat) => {
                                                return (
                                                    <div key={dat.id} className={`w-full p-3 gap-3 md:gap-5 rounded-2xl flex flex-row justify-start items-center ${dat?.place === 1 ? 'bg-[#E5C97F]' : dat?.place === 2 ? 'bg-[#9FABB9]' : dat?.place === 3 ? 'bg-[#AC9380]' : 'bg-white'}`}>
                                                        <h1 className={`text-2xl md:text-3xl 2xl:text-4xl font-extrabold ${dat?.place <= 3 ? 'text-white' : 'text-[#606060]'}`}>{ordinal(dat?.place, 'number')}<span className='text-lg 2xl:text-xl font-normal'>{ordinal(dat?.place, 'suffix')}</span></h1>
                                                        {dat?.profilePic !== '' && dat?.profilePic !== null && dat?.profilePic !== undefined ?
                                                            <img src={dat?.profilePic} alt={dat?.displayName} referrerPolicy='no-referrer' className='w-12 2xl:w-16 aspect-square rounded-full' />
                                                        :
                                                            <div className='w-12 2xl:w-16 aspect-square rounded-full bg-[#073B4C] flex justify-center items-center'>
                                                                {!isLoading && dat?.displayName !== undefined ?
                                                                    <h1 className='text-white text-xl 2xl:text-2xl font-semibold uppercase'>{`${dat?.displayName}`.slice(0, 1)}</h1>
                                                                : null }
                                                            </div>
                                                        }
                                                        <h1 className={`text-xl md:text-2xl 2xl:text-3xl ${dat?.place <= 3 ? 'text-white font-bold' : 'text-black font-semibold'}`}>{dat?.displayName}</h1>
                                                        <div className={isMobile ? 'ml-auto min-w-[2.5rem] flex flex-col justify-center items-end' : 'ml-auto flex flex-row justify-end items-center gap-2'}>
                                                            <h1 className={`text-2xl md:text-3xl 2xl:text-4xl ${dat?.place <= 3 ? 'text-white font-bold' : 'text-black font-semibold'}`}>{dat?.total}</h1>
                                                            <h1 className={`text-right ml-auto text-xs md:text-lg 2xl:text-xl font-normal ${dat?.place <= 3 ? 'text-white' : 'text-black'}`}>{isMobile ? 'Points' : 'Total Points'}</h1>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null }
                                    </div>
                                </motion.div>
                            : null }
                            {teamLeaderboardVisible ?
                                <motion.div className='w-full backdrop-blur-3xl bg-[#FFFFFF25] md:border-[0.5px] md:border-[#FFFFFF50] md:shadow-2xl md:shadow-black rounded-2xl flex flex-col justify-start items-start p-3 gap-3 2xl:p-7 2xl:gap-7' {...blockAnimation} transition={{ delay: 0.2 }}>
                                    <div className='w-full flex flex-row justify-between items-center'>
                                        <div className='flex flex-row justify-center items-center gap-3'>
                                            <h1 className='text-lg 2xl:text-2xl font-bold mr-auto text-white'>Team Leaderboard</h1>
                                            <h1 className='text-base 2xl:text-xl font-semibold text-white'>•</h1>
                                            <button className='text-sm 2xl:text-lg font-normal text-white opacity-75' onClick={() => {
                                                setIsLoading(true);
                                                getFullTeamLeaderboard();
                                            }}>{isMobile ? 'Refresh' : 'Refresh Data'}</button>
                                        </div>
                                        <button className='text-lg 2xl:text-2xl font-normal text-white uppercase' onClick={() => {
                                            setTeamLeaderboardVisible(false);
                                            setFullTeamLeaderboard([]);
                                        }}>Back</button>
                                    </div>
                                    <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-5'>
                                        {!isLoading && fullTeamLeaderboard.length !== 0 ?
                                            fullTeamLeaderboard.map((dat) => {
                                                return (
                                                    <div key={dat.id} className={`w-full p-3 gap-3 rounded-2xl flex flex-col justify-start items-center ${dat?.place === 1 ? 'bg-[#E5C97F]' : dat?.place === 2 ? 'bg-[#9FABB9]' : dat?.place === 3 ? 'bg-[#AC9380]' : 'bg-white'}`}>
                                                        <div className='w-full flex flex-row justify-between items-center'>
                                                            <h1 className={`text-2xl md:text-3xl 2xl:text-4xl font-extrabold ${dat?.place <= 3 ? 'text-white' : 'text-[#606060]'}`}>{ordinal(dat?.place, 'number')}<span className='text-lg 2xl:text-xl font-normal'>{ordinal(dat?.place, 'suffix')}</span></h1>
                                                            <h1 className={`text-lg md:text-xl 2xl:text-2xl ${dat?.place <= 3 ? 'text-white font-bold' : 'text-black font-semibold'}`}>{dat?.teamName}</h1>
                                                        </div>
                                                        <div className={`w-full h-[1px] ${dat?.place <= 3 ? 'bg-white' : 'bg-black'}`} />
                                                        <div className='w-full flex flex-col justify-start items-center gap-1'>
                                                            {dat?.members.map((mem) => {
                                                                return (
                                                                    <div className='w-full flex flex-row justify-between items-center'>
                                                                        <h1 className={`text-lg md:text-xl 2xl:text-2xl font-semibold ${dat?.place <= 3 ? 'text-white' : 'text-black'}`}>{mem?.displayName}</h1>
                                                                        <h1 className={`text-base md:text-lg 2xl:text-xl font-normal ${dat?.place <= 3 ? 'text-white' : 'text-black'}`}>{mem?.total}</h1>
                                                                    </div>
                                                                )
                                                            })}
                                                            <h1 className={`mt-4 text-sm md:text-base 2xl:text-lg font-bold ${dat?.place <= 3 ? 'text-white' : 'text-black'}`}>{dat?.average} Average Points</h1>
                                                            <h1 className={`text-sm md:text-base 2xl:text-lg font-normal ${dat?.place <= 3 ? 'text-white' : 'text-black'}`}>{dat?.total} Total Points</h1>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null }
                                    </div>
                                </motion.div>
                            : null }
                            {!individualLeaderboardVisible && !teamLeaderboardVisible ?
                                <div className='w-full grid grid-cols-1 md:grid-cols-6 gap-5'>
                                    <motion.div className='w-full h-52 md:h-72 md:col-span-3 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.2 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center px-3 pt-3 2xl:px-7 2xl:pt-7'>
                                            <button className='w-full h-full flex flex-col justify-between items-center' onClick={() => {
                                                setIsLoading(true);
                                                setIndividualLeaderboardVisible(true);
                                                getFullIndividualLeaderboard();
                                            }}>
                                                <h1 className='w-full text-left text-lg 2xl:text-xl font-bold'>Individual Leaderboard</h1>
                                                <div className='w-full h-full flex flex-row justify-evenly items-end'>
                                                    {individualPodium.map((item) => {
                                                        return (
                                                            <div key={item.place} className={`w-1/6 ${item.place === 1 ? 'h-[100%] 2xl:h-[95%]' : item.place === 2 ? 'h-[85%] 2xl:h-[80%]' : item.place === 3 ? 'h-[75%] 2xl:h-[70%]' : item.place === 4 ? 'h-[65%] 2xl:h-[60%]' : 'h-[55%] 2xl:h-[50%]'} flex flex-col justify-start items-center gap-3`}>
                                                                {item?.profilePic !== '' && item?.profilePic !== null && item?.profilePic !== undefined ?
                                                                    <img src={item?.profilePic} alt={item.place === 1 ? 'First Place' : item.place === 2 ? 'Second Place' : item.place === 3 ? 'Third Place' : item.place === 4 ? 'Fourth Place' : 'Fifth Place'} referrerPolicy='no-referrer' className='h-8 2xl:h-14 aspect-square rounded-full' />
                                                                :
                                                                    <div className='h-8 2xl:h-14 aspect-square rounded-full bg-[#073B4C] flex justify-center items-center'>
                                                                        {!isLoading && item?.displayName !== undefined ?
                                                                            <h1 className='text-white text-lg 2xl:text-xl font-semibold uppercase'>{`${item?.displayName}`.slice(0, 1)}</h1>
                                                                        : null }
                                                                    </div>
                                                                }
                                                                <div className={`w-full h-full ${item.place === 1 ? 'bg-[#E5C97F]' : item.place === 2 ? 'bg-[#9FABB9]' : item.place === 3 ? 'bg-[#AC9380]' : 'bg-slate-700'} rounded-tl-2xl rounded-tr-2xl 2xl:rounded-tl-3xl 2xl:rounded-tr-3xl flex flex-col justify-start items-center`}>
                                                                    <h1 className='text-white text-base 2xl:text-2xl font-black mt-2'>{item.place === 1 ? '1st' : item.place === 2 ? '2nd' : item.place === 3 ? '3rd' : item.place === 4 ? '4th' : '5th'}</h1>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </button>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full h-52 md:h-72 md:col-span-3 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.4 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center px-3 pt-3 2xl:px-7 2xl:pt-7'>
                                            <button className='w-full h-full flex flex-col justify-between items-center' onClick={() => {
                                                setIsLoading(true);
                                                setTeamLeaderboardVisible(true);
                                                getFullTeamLeaderboard();
                                            }}>
                                                <h1 className='w-full text-left text-lg 2xl:text-xl font-bold'>Team Leaderboard</h1>
                                                <div className='w-full h-full flex flex-row justify-evenly items-end'>
                                                    {teamPodium.map((item) => {
                                                        return (
                                                            <div key={item.place} className={`w-1/6 ${item.place === 1 ? 'h-[100%] 2xl:h-[95%]' : item.place === 2 ? 'h-[85%] 2xl:h-[80%]' : item.place === 3 ? 'h-[75%] 2xl:h-[70%]' : item.place === 4 ? 'h-[65%] 2xl:h-[60%]' : 'h-[55%] 2xl:h-[50%]'} flex flex-col justify-start items-center gap-3`}>
                                                                {item?.profilePic !== '' && item?.profilePic !== null && item?.profilePic !== undefined ?
                                                                    <img src={item?.profilePic} alt={item.place === 1 ? 'First Place' : item.place === 2 ? 'Second Place' : item.place === 3 ? 'Third Place' : item.place === 4 ? 'Fourth Place' : 'Fifth Place'} referrerPolicy='no-referrer' className='h-8 2xl:h-14 aspect-square rounded-full' />
                                                                :
                                                                    <div className='h-8 2xl:h-14 aspect-square rounded-full bg-[#073B4C] flex justify-center items-center'>
                                                                        {!isLoading && item?.displayName !== undefined ?
                                                                            <h1 className='text-white text-lg 2xl:text-xl font-semibold uppercase'>{`${item?.displayName}`.slice(0, 1)}</h1>
                                                                        : null }
                                                                    </div>
                                                                }
                                                                <div className={`w-full h-full ${item.place === 1 ? 'bg-[#E5C97F]' : item.place === 2 ? 'bg-[#9FABB9]' : item.place === 3 ? 'bg-[#AC9380]' : 'bg-slate-700'} rounded-tl-2xl rounded-tr-2xl 2xl:rounded-tl-3xl 2xl:rounded-tr-3xl flex flex-col justify-start items-center`}>
                                                                    <h1 className='text-white text-base 2xl:text-2xl font-black mt-2'>{item.place === 1 ? '1st' : item.place === 2 ? '2nd' : item.place === 3 ? '3rd' : item.place === 4 ? '4th' : '5th'}</h1>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </button>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.6 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Number Of Participants</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>278</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.65 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Total Points</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>32,721</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.7 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Average Points</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>117.2818</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.75 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Total Steps</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>83,876,129</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.75 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Minutes Spent In Devotion</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>~178,380</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-2 2xl:col-span-1 rounded-2xl bg-white overflow-hidden' id='container' {...blockAnimation} transition={{ delay: 0.75 }}>
                                        <div className='w-full h-full flex flex-col justify-center items-center py-3 2xl:py-7 gap-1'>
                                            <h2 className='text-md 2xl:text-xl font-semibold opacity-50'>Number Of Logs</h2>
                                            <h1 className='text-2xl 2xl:text-4xl font-black'>9,100</h1>
                                        </div>
                                    </motion.div>
                                    <motion.div className='w-full md:col-span-6 rounded-2xl bg-white p-3' {...blockAnimation} transition={{ delay: 0.8 }}>
                                        <div className='w-full flex flex-col justify-center items-center gap-7'>
                                            {allActivityData.length === 0 ?
                                                <h2 className='my-5 text-black text-xl 2xl:2xl font-normal'>No one has logged yet... 🤷‍♂️</h2>
                                            : null}
                                            {allActivityData.map((item) => {
                                                return (
                                                    <div key={item.id} className='w-full flex flex-col justify-center items-center gap-3'>
                                                        <div className='w-full flex flex-row justify-start items-start gap-3 overflow-hidden'>
                                                            {item?.profilePic !== '' && item?.profilePic !== null && item?.profilePic !== undefined ?
                                                                <img src={item?.profilePic} alt={item?.displayName} referrerPolicy='no-referrer' className='w-12 aspect-square rounded-full' />
                                                            :
                                                                <div className='w-12 aspect-square rounded-full bg-[#073B4C] flex justify-center items-center'>
                                                                    {!isLoading && item?.displayName !== undefined ?
                                                                        <h1 className='text-white text-xl 2xl:text-2xl font-semibold uppercase'>{`${item?.displayName}`.slice(0, 1)}</h1>
                                                                    : null }
                                                                </div>
                                                            }
                                                            <div className='w-full max-w-[55%] md:max-w-full flex flex-col justify-center items-start'>
                                                                <h1 className='text-lg 2xl:text-2xl font-bold'>{item?.displayName}</h1>
                                                                {item?.message !== '' ?
                                                                    <h2 className='w-full text-sm 2xl:text-base font-normal break-words overflow-hidden text-ellipsis'>{item?.message}</h2>
                                                                : null }
                                                            </div>
                                                            <div className='flex flex-col justify-center items-end gap-1'>
                                                                <div className='flex flex-row justify-end items-center gap-3'>
                                                                    {item?.walk > 0 ?
                                                                        <img src={walkFill} alt='Walk' className='w-4 aspect-square invert' />
                                                                    : null }
                                                                    {item?.weight >= 1 ?
                                                                        <img src={weightFill} alt='Weight' className='w-4 aspect-square invert' />
                                                                    : null }
                                                                    {item?.wait >= 1 ?
                                                                        <img src={waitFill} alt='Wait' className='w-4 aspect-square invert' />
                                                                    : null }
                                                                    {item?.whey >= 1 ?
                                                                        <img src={wheyFill} alt='Whey' className='w-4 aspect-square invert' />
                                                                    : null }
                                                                </div>
                                                                {item?.walk >= 1 ?
                                                                    <h1 className='w-full text-xs 2xl:text-sm font-normal text-right whitespace-nowrap uppercase opacity-75'>Steps: <span className='font-bold'>{item?.walk}</span></h1>
                                                                : null }
                                                            </div>
                                                        </div>
                                                        <div className='w-full flex flex-row justify-start items-center gap-2 flex-wrap'>
                                                            {Object.entries(item.reactions).map((value) => {
                                                                return (
                                                                    <motion.button key={value[0]} className='flex flex-row justify-start items-center gap-1 py-1 px-[6px] rounded-xl' animate={{ backgroundColor: value[1]?.includes(currentAuthUser?.uid) ? '#ADD8E6' : '#EFEFEF' }} whileHover={{ backgroundColor: '#ADD8E6' }} transition={{ duration: 0.2 }} onClick={() => {
                                                                        addReaction(item?.id, value[0]);
                                                                    }}>
                                                                        <Emoji unified={value[0]} size={16} />
                                                                        <h1 className='text-xs 2xl:text-sm font-light'>{value[1]?.length}</h1>
                                                                    </motion.button>
                                                                )
                                                            })}
                                                            <motion.button className='ml-1 flex flex-row justify-start items-center gap-[6px]' animate={{ opacity: '50%' }} whileHover={{ opacity: '100%' }} transition={{ duration: 0.2 }} onClick={() => {
                                                                if (emojiPickerVisible !== null && emojiPickerVisible !== '') {
                                                                    setEmojiPickerVisible(null);
                                                                } else {
                                                                    setEmojiPickerVisible(`${item?.id}`);
                                                                }
                                                            }}>
                                                                <img src={addEmojiIcon} alt='Add Reaction' className='w-4 aspect-square' />
                                                                {Object.keys(item.reactions).length === 0 || item?.reactions === undefined || item?.reactions === null ?
                                                                    <h1 className='text-xs 2xl:text-sm font-light'>Add Reaction</h1>
                                                                : null}
                                                            </motion.button>
                                                        </div>
                                                        {emojiPickerVisible !== null && emojiPickerVisible !== '' && emojiPickerVisible === `${item?.id}` ?
                                                            <motion.div className='w-full flex flex-row justify-start items-center' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                                                                <EmojiPicker width='100%' height={350} autoFocusSearch={false} previewConfig={{ showPreview: false }} onEmojiClick={(e) => {
                                                                    addReaction(item?.id, e.unified)
                                                                    setEmojiPickerVisible(null);
                                                                }}/>
                                                            </motion.div>
                                                        : null }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </motion.div>
                                </div>
                            : null }
                        </motion.div>
                    : null }
                    <div className={`w-full ${statsVisible ? 'h-full pb-16' : ''} md:w-1/3 md:h-full md:rounded-tr-3xl md:rounded-br-3xl md:bg-[#FFFFFF25] flex flex-col justify-start items-center p-5 gap-5 overflow-hidden`}>
                        <div className='w-full flex flex-row justify-between items-center'>
                            <button className='flex flex-row items-center gap-2 2xl:gap-4'>
                                {userData?.profilePic !== '' && userData?.profilePic !== null && userData?.profilePic !== undefined ?
                                    <img src={userData?.profilePic} alt={userData?.displayName} referrerPolicy='no-referrer' className='w-12 2xl:w-16 aspect-square rounded-full' />
                                :
                                    <div className='w-12 2xl:w-16 aspect-square rounded-full bg-[#073B4C] flex justify-center items-center'>
                                        {!isLoading && userData?.displayName !== undefined ?
                                            <h1 className='text-white text-xl 2xl:text-2xl font-semibold uppercase'>{`${userData?.displayName}`.slice(0, 1)}</h1>
                                        : null }
                                    </div>
                                }
                                <div className='flex flex-col justify-center items-start'>
                                    {!isLoading && userData?.displayName !== undefined ?
                                        <>
                                            <h1 className='text-white text-base 2xl:text-2xl font-semibold 2xl:font-bold'>{userData?.displayName}</h1>
                                            {userData?.team !== undefined && teamData?.teamName !== undefined ?
                                                <h1 className='text-white text-sm 2xl:text-lg font-light 2xl:font-normal'>{teamData?.teamName}</h1>
                                            :
                                                <h1 className='text-white text-sm 2xl:text-lg font-light 2xl:font-normal'>Individual</h1>
                                            }
                                        </>
                                    : null }
                                </div>
                            </button>
                            {/* <button className='w-12 2xl:w-16 aspect-square rounded-full bg-[#00000020] shadow-inner shadow-[#00000050] flex justify-center items-center' onClick={() => {
                                if (notificationsVisible) {
                                    setNotificationsVisible(false);
                                } else {
                                    setNotificationsVisible(true);
                                }
                            }}>
                                <img className='w-1/2 aspect-square' src={notificationTransparent} alt='Notifications' />
                            </button> */}
                        </div>
                        {!isMobile ?
                            // <motion.div className='w-[205%] h-full flex flex-row justify-center items-center gap-5 overflow-hidden' initial={{ x: '26%' }} animate={{ x: notificationsVisible ? '-26%' : '26%' }} transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}>
                            <motion.div className='w-full h-full flex flex-row justify-center items-center gap-5 overflow-hidden'>
                                <div className='w-full h-full flex flex-col justify-start items-center gap-5 overflow-y-scroll'>
                                    <div className='w-full grid grid-cols-2 gap-5'>
                                        <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#D66460]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.1 }}>
                                            <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Walk</h1>
                                                <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                    <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={walkTransparent} alt='Walk' />
                                                </div>
                                            </div>
                                            {!isLoading ?
                                                <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.walk ? pointsData?.walk : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                            : null }
                                        </motion.div>
                                        <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#67BBA2]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.2 }}>
                                            <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Weight</h1>
                                                <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                    <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={weightTransparent} alt='Weight' />
                                                </div>
                                            </div>
                                            {!isLoading ?
                                                <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.weight ? pointsData?.weight : '0'}<span className='font-normal'> / 35 <span className='text-[10px]'>Days</span></span></h1>
                                            : null }
                                        </motion.div>
                                        <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#C272BA]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.3 }}>
                                            <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Wait</h1>
                                                <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                    <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={waitTransparent} alt='Wait' />
                                                </div>
                                            </div>
                                            {!isLoading ?
                                                <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.wait ? pointsData?.wait : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                            : null }
                                        </motion.div>
                                        <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#D2C158]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.4 }}>
                                            <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Whey</h1>
                                                <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                    <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={wheyTransparent} alt='Whey' />
                                                </div>
                                            </div>
                                            {!isLoading ?
                                                <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.whey ? pointsData?.whey : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                            : null }
                                        </motion.div>
                                    </div>
                                    {!isLoading ?
                                        <>
                                            <div className='w-full flex flex-row justify-between items-center'>
                                                <div className='w-full flex flex-col'>
                                                    <h2 className='text-white text-base 2xl:text-lg font-normal 2xl:font-semibold'>Total Points</h2>
                                                    <h1 className='text-white text-2xl 2xl:text-3xl font-black uppercase'>{pointsData?.total ? pointsData?.total : '0'}</h1>
                                                </div>
                                                <div className='w-full flex flex-col text-right'>
                                                    <h2 className='text-white text-base 2xl:text-lg font-normal 2xl:font-semibold'>Total Steps</h2>
                                                    <h1 className='text-white text-2xl 2xl:text-3xl font-black uppercase'>{pointsData?.steps ? pointsData?.steps : '0'}</h1>
                                                </div>
                                            </div>
                                            <div className='mt-auto w-full flex flex-col justify-center items-start gap-3'>
                                                <div className='w-full flex flex-row justify-start items-end gap-3'>
                                                    <div className='w-[40%] flex flex-col justify-end items-start gap-2'>
                                                        <h2 className='text-white text-sm 2xl:text-base font-normal 2xl:font-semibold uppercase'>Individual Progress</h2>
                                                        <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold'>{pointsData?.total || '0'}/155 Points</h2>
                                                    </div>
                                                    <div className='w-[27%] h-5 flex flex-row justify-end items-center gap-2'>
                                                        <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MIN Points</h2>
                                                        <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Min Points' />
                                                    </div>
                                                    <div className='w-[33%] h-5 flex flex-row justify-end items-center gap-2'>
                                                        <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MAX Points</h2>
                                                        <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                    </div>
                                                </div>
                                                <div className='w-full h-5 backdrop-blur-3xl bg-[#FFFFFF25] rounded-full mb-5'>
                                                    <div className='h-full bg-[#E6311D] rounded-full' style={{ width: `${pointsData?.total !== undefined && pointsData?.total !== 0 ? (Math.round((pointsData?.total / 155) * 100)) : 0}%` }} />
                                                </div>
                                                {userData?.team !== '' && userData?.team !== undefined ?
                                                    <>
                                                        <div className='w-full flex flex-row justify-start items-end gap-3'>
                                                            <div className='w-[40%] flex flex-col justify-end items-start gap-2'>
                                                                <h2 className='text-white text-sm 2xl:text-base font-normal 2xl:font-semibold uppercase'>Team Progress</h2>
                                                                <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold'>{teamData?.total || '0'}/{teamData?.members * 155} Points</h2>
                                                            </div>
                                                            <div className='w-[27%] h-5 flex flex-row justify-end items-center gap-2'>
                                                                <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MIN Points</h2>
                                                                <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Min Points' />
                                                            </div>
                                                            <div className='w-[33%] h-5 flex flex-row justify-end items-center gap-2'>
                                                                <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MAX Points</h2>
                                                                <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                            </div>
                                                        </div>
                                                        <div className='w-full h-5 backdrop-blur-3xl bg-[#FFFFFF25] rounded-full'>
                                                            <div className='h-full bg-[#E6311D] rounded-full'  style={{ width: `${teamData?.total !== undefined && teamData?.total !== 0 ? (Math.round((teamData?.total / (teamData?.members * 155)) * 100)) : 0}%` }}  />
                                                        </div>
                                                    </>
                                                : null }
                                            </div>
                                        </>
                                    : null }
                                </div>
                                {/* <div className={`w-full h-full flex flex-col justify-start items-center gap-5 overflow-y-scroll ${notificationData.length === 0 ? 'border-t border-t-[#FFFFFF75]' : ''}`}>
                                    <h2 className='mt-5 text-white text-xl 2xl:2xl font-normal'>No Older Notifications</h2>
                                    {notificationData.length === 0 ?
                                        <h2 className='mt-5 text-white text-xl 2xl:2xl font-normal'>No Older Notifications</h2>
                                    :
                                        notificationData.map((not) => {
                                            return (
                                                <AnimatePresence initial={false} key={not}>
                                                    <motion.div className='w-[90%] rounded-2xl' style={{ backgroundColor: not === 2 ? '#FFDB58' : '#FFFFFF' }} initial={{ opacity: 0, y: 50, scale: 0.3 }} animate={{ opacity: 1, y: 0, scale: 1 }} exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}>
                                                        <div className='h-[100px] flex flex-row justify-end items-start p-3'>
                                                            <button className=''>
                                                                <svg width='23' height='23' viewBox='0 0 23 23'>
                                                                    <motion.path className='fill-transparent stroke-[#606060] stroke-[3]' strokeLinecap='round' d='M 3 16.5 L 17 2.5' />
                                                                    <motion.path className='fill-transparent stroke-[#606060] stroke-[3]' strokeLinecap='round' d='M 3 2.5 L 17 16.346' />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </motion.div>
                                                </AnimatePresence>
                                            )
                                        })
                                    }
                                </div> */}
                            </motion.div>
                        :
                            statsVisible ?
                                // <motion.div className='w-[205%] h-full flex flex-row justify-center items-center gap-5 overflow-hidden' initial={{ x: '26%' }} animate={{ x: notificationsVisible ? '-26%' : '26%' }} transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}>
                                <motion.div className='w-full h-full flex flex-row justify-center items-center gap-5 overflow-hidden'>
                                    <div className='w-full h-full flex flex-col justify-start items-center gap-5 overflow-y-scroll'>
                                        <div className='w-full grid grid-cols-2 gap-5'>
                                            <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#D66460]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.1 }}>
                                                <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                    <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Walk</h1>
                                                    <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                        <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={walkTransparent} alt='Walk' />
                                                    </div>
                                                </div>
                                                {!isLoading ?
                                                    <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.walk ? pointsData?.walk : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                                : null }
                                            </motion.div>
                                            <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#67BBA2]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.2 }}>
                                                <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                    <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Weight</h1>
                                                    <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                        <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={weightTransparent} alt='Weight' />
                                                    </div>
                                                </div>
                                                {!isLoading ?
                                                    <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.weight ? pointsData?.weight : '0'}<span className='font-normal'> / 35 <span className='text-[10px]'>Days</span></span></h1>
                                                : null }
                                            </motion.div>
                                            <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#C272BA]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.3 }}>
                                                <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                    <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Wait</h1>
                                                    <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                        <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={waitTransparent} alt='Wait' />
                                                    </div>
                                                </div>
                                                {!isLoading ?
                                                    <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.wait ? pointsData?.wait : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                                : null }
                                            </motion.div>
                                            <motion.div className={`w-full h-28 md:h-full md:aspect-square rounded-2xl ${!isLoading ? 'bg-[#D2C158]' : 'bg-[#FFFFFF25] backdrop-blur-3xl'} flex flex-col justify-between items-start p-4 overflow-hidden -z-20`} {...blockAnimation} transition={{ delay: 0.4 }}>
                                                <div className='w-full h-1/2 md:h-full flex flex-row justify-between items-start'>
                                                    <h1 className='text-white text-xl 2xl:text-2xl font-semibold 2xl:font-extrabold uppercase'>Whey</h1>
                                                    <div className='h-full aspect-square -z-10 opacity-[15%]'>
                                                        <img className='w-full h-full scale-[300%] md:scale-[200%] 2xl:scale-[150%] translate-y-5 -translate-x-3' src={wheyTransparent} alt='Whey' />
                                                    </div>
                                                </div>
                                                {!isLoading ?
                                                    <h1 className='text-white text-xl 2xl:text-3xl font-black align-text-bottom uppercase'>{pointsData?.whey ? pointsData?.whey : '0'}<span className='font-normal'> / 40 <span className='text-[10px]'>Days</span></span></h1>
                                                : null }
                                            </motion.div>
                                        </div>
                                        {!isLoading ?
                                            <>
                                                <div className='w-full flex flex-row justify-between items-center'>
                                                    <div className='w-full flex flex-col'>
                                                        <h2 className='text-white text-base 2xl:text-lg font-normal 2xl:font-semibold'>Total Points</h2>
                                                        <h1 className='text-white text-2xl 2xl:text-3xl font-black uppercase'>{pointsData?.total ? pointsData?.total : '0'}</h1>
                                                    </div>
                                                    <div className='w-full flex flex-col text-right'>
                                                        <h2 className='text-white text-base 2xl:text-lg font-normal 2xl:font-semibold'>Total Steps</h2>
                                                        <h1 className='text-white text-2xl 2xl:text-3xl font-black uppercase'>{pointsData?.steps ? pointsData?.steps : '0'}</h1>
                                                    </div>
                                                </div>
                                                <div className='mt-5 w-full flex flex-col justify-center items-start gap-3'>
                                                    <div className='w-full flex flex-row justify-start items-end gap-3'>
                                                        <div className='w-[40%] flex flex-col justify-end items-start gap-2'>
                                                            <h2 className='text-white text-xs 2xl:text-base font-normal 2xl:font-semibold uppercase'>Individual Progress</h2>
                                                            <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold'>{pointsData?.total || '0'}/155 Points</h2>
                                                        </div>
                                                        <div className='w-[27%] h-5 flex flex-row justify-end items-center gap-2'>
                                                            <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MIN Points</h2>
                                                            <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                        </div>
                                                        <div className='w-[33%] h-5 flex flex-row justify-end items-center gap-2'>
                                                            <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MAX Points</h2>
                                                            <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                        </div>
                                                    </div>
                                                    <div className='w-full h-5 backdrop-blur-3xl bg-[#FFFFFF25] rounded-full mb-5'>
                                                        <div className='h-full bg-[#E6311D] rounded-full' style={{ width: `${pointsData?.total !== undefined && pointsData?.total !== 0 ? (Math.round((pointsData?.total / 155) * 100)) : 0}%` }} />
                                                    </div>
                                                    {userData?.team !== '' && userData?.team !== undefined ?
                                                        <>
                                                            <div className='w-full flex flex-row justify-start items-end gap-3'>
                                                                <div className='w-[40%] flex flex-col justify-end items-start gap-2'>
                                                                    <h2 className='text-white text-xs lg:text-base font-normal 2xl:font-semibold uppercase'>Team Progress</h2>
                                                                    <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold'>{teamData?.total || '0'}/{teamData?.members * 155} Points</h2>
                                                                </div>
                                                                <div className='w-[27%] h-5 flex flex-row justify-end items-center gap-2'>
                                                                    <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MIN Points</h2>
                                                                    <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                                </div>
                                                                <div className='w-[33%] h-5 flex flex-row justify-end items-center gap-2'>
                                                                    <h2 className='text-white text-[0.625rem] lg:text-sm font-normal 2xl:font-semibold text-right'>MAX Points</h2>
                                                                    <img className='h-full aspect-square invert rotate-180' src={statsArrow} alt='Max Points' />
                                                                </div>
                                                            </div>
                                                            <div className='w-full h-5 backdrop-blur-3xl bg-[#FFFFFF25] rounded-full'>
                                                                <div className='h-full bg-[#E6311D] rounded-full'  style={{ width: `${teamData?.total !== undefined && teamData?.total !== 0 ? (Math.round((teamData?.total / (teamData?.members * 155)) * 100)) : 0}%` }}  />
                                                            </div>
                                                        </>
                                                    : null }
                                                </div>
                                            </>
                                        : null }
                                    </div>
                                    {/* <div className={`w-full h-full flex flex-col justify-start items-center gap-5 overflow-y-scroll ${notificationData.length === 0 ? 'border-t border-t-[#FFFFFF75]' : ''}`}>
                                        <h2 className='mt-5 text-white text-xl 2xl:2xl font-normal'>No Older Notifications</h2>
                                        {notificationData.length === 0 ?
                                            <h2 className='mt-5 text-white text-xl 2xl:2xl font-normal'>No Older Notifications</h2>
                                        :
                                            notificationData.map((not) => {
                                                return (
                                                    <AnimatePresence initial={false} key={not}>
                                                        <motion.div className='w-[90%] rounded-2xl' style={{ backgroundColor: not === 2 ? '#FFDB58' : '#FFFFFF' }} initial={{ opacity: 0, y: 50, scale: 0.3 }} animate={{ opacity: 1, y: 0, scale: 1 }} exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}>
                                                            <div className='h-[100px] flex flex-row justify-end items-start p-3'>
                                                                <button className=''>
                                                                    <svg width='23' height='23' viewBox='0 0 23 23'>
                                                                        <motion.path className='fill-transparent stroke-[#606060] stroke-[3]' strokeLinecap='round' d='M 3 16.5 L 17 2.5' />
                                                                        <motion.path className='fill-transparent stroke-[#606060] stroke-[3]' strokeLinecap='round' d='M 3 2.5 L 17 16.346' />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </motion.div>
                                                    </AnimatePresence>
                                                )
                                            })
                                        }
                                    </div> */}
                                </motion.div>
                            : null
                        }
                    </div>
                    {isMobile ?
                        <button className='fixed w-full h-12 backdrop-blur-3xl bg-[#00000075] bottom-0 px-5 py-2 flex flex-row justify-between items-center' onClick={() => {
                            if (statsVisible) {
                                setStatsVisible(false);
                            } else {
                                setStatsVisible(true);
                            }
                        }}>
                            <h1 className='w-full text-left text-lg 2xl:text-xl font-bold text-white uppercase'>{statsVisible ? 'Close Stats' : 'Open Stats'}</h1>
                            <img className='h-4/6 aspect-square invert' src={statsArrow} alt='Open Stats' />
                        </button>
                    : null }
                </motion.div>
            </div>
        </div>
    )
}

export default Home;