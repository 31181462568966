import { useEffect } from 'react';
import { motion, useAnimate } from 'framer-motion';

// const originalSpeedX = 2;
// const originalSpeedY = 1;
// const originalSpeedT = 0.25;

function Loader() {
    const text = 'See What God Can Do Through You.';
    const characters = text.split('');

    const radius = 100;
    const fontSize = '18px';
    const letterSpacing = 11;

    const [scope, animate] = useAnimate();

    useEffect(() => {
        const animateLoader = async () => {
            const letterAnimation = [];

            characters.forEach((_, i) => {
                letterAnimation.push([
                    `.letter-${i}`,
                    { opacity: 0 },
                    { duration: 0.3, at: i === 0 ? '+0.8' : '-0.28' }
                ]);
            });

            characters.forEach((_, i) => {
                letterAnimation.push([
                    `.letter-${i}`,
                    { opacity: 1 },
                    { duration: 0.3, at: i === 0 ? '+0.8' : '-0.28' }
                ]);
            });

            animate(letterAnimation, {
                ease: 'linear',
                repeat: Infinity
            });

            animate(
                scope.current,
                { rotate: 360 },
                { duration: 8, ease: 'linear', repeat: Infinity }
            );
        };

        animateLoader();
    }, []);

    // const [x1, setX1] = useState(48);
    // const [speedX1, setSpeedX1] = useState(originalSpeedX);
    // const [y1, setY1] = useState(98);
    // const [speedY1, setSpeedY1] = useState(-originalSpeedY);
    // const [t1, setT1] = useState(43);
    // const [speedT1, setSpeedT1] = useState(originalSpeedT);
    
    // const [x2, setX2] = useState(43);
    // const [speedX2, setSpeedX2] = useState(-originalSpeedX);
    // const [y2, setY2] = useState(56);
    // const [speedY2, setSpeedY2] = useState(-originalSpeedY);
    // const [t2, setT2] = useState(60);
    // const [speedT2, setSpeedT2] = useState(-originalSpeedT);

    // const [x3, setX3] = useState(50);
    // const [speedX3, setSpeedX3] = useState(-originalSpeedX);
    // const [y3, setY3] = useState(4);
    // const [speedY3, setSpeedY3] = useState(originalSpeedY);
    // const [t3, setT3] = useState(47);
    // const [speedT3, setSpeedT3] = useState(-originalSpeedT);

    // const [x4, setX4] = useState(38);
    // const [speedX4, setSpeedX4] = useState(originalSpeedX);
    // const [y4, setY4] = useState(75);
    // const [speedY4, setSpeedY4] = useState(originalSpeedY);
    // const [t4, setT4] = useState(97);
    // const [speedT4, setSpeedT4] = useState(originalSpeedT);

    return(
        <motion.div ref={scope} className='relative aspect-square' style={{ width: radius * 2 }}>
            <p aria-label={text} />
            <p aria-hidden='true' className='text'>
                {characters.map((ch, i) => (
                    <motion.span
                        key={i}
                        className={`absolute top-0 left-1/2 font-sans font-bold text-white uppercase letter-${i}`}
                        style={{
                            transformOrigin: `0 ${radius}px`,
                            transform: `rotate(${i * letterSpacing}deg)`,
                            fontSize
                        }}
                    >
                        {ch}
                    </motion.span>
                ))}
            </p>
        </motion.div>
    )
}

export default Loader;